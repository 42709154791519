.text-editor-general {
    .title-input {
        width: 100%;
        border-bottom: none ;
        border-radius: 8px 8px 0 0;
    }
    .ck-rounded-corners  {
        .ck.ck-editor__top {
            .ck-sticky-panel {
                .ck-toolbar {
                    height: 39px;
                    border: solid 1px #d5dfdb;
                }
            }
        }
    }
    .editorNoTitle {
        .ck-rounded-corners  {
            .ck.ck-editor__top {
                .ck-sticky-panel {
                    .ck-toolbar {
                        min-height: 39px;
                        border: solid 1px #d5dfdb;
                        border-radius: 8px 8px 0 0;
                    }
                }
            }
        }

    }
    .ck.ck-toolbar.ck-toolbar_grouping>.ck-toolbar__items {
        height: 100%;
    }
    .ck.ck-button {
        color: #3F544E;
        height: 100%;    
        margin-top: 0 !important;    
        margin-bottom: 0 !important;
    }
    .ck.ck-button.ck-on, a.ck.ck-button.ck-on {
        height: 100%;
        color: #1d2623;
        background: #f3f7f6;
    }
    .ck.ck-editor__editable_inline {
        height: 178px;
    }
    .ck.ck-editor__editable_inline {
        padding: 0 23px;
    }
    .ck .ck-placeholder:before, .ck.ck-placeholder:before {
        font: 14px "GothamMedium", sans-serif;
       color: #8C939C;
       letter-spacing: -0.23px;
    }
    .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
        border-color: var(--ck-color-base-border);
        border: solid 1px #d5dfdb;
        border-top: none;
    }
    .ck.ck-editor__editable_inline>:first-child {
        margin-top: 14px;
        margin-bottom: 14px;
    }
    .ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
        border-color: #b3ccc2;
        box-shadow: 0 0 0 2px rgba(179, 204, 194, 0.2);
        
    } 
    .ck.ck-editor__editable_inline>:last-child {
        margin-bottom: 0;
    }
    .ck.ck-editor__editable_inline {

        font: 14px "GothamMedium", sans-serif;
        letter-spacing: -0.23px;
        color: #3F544E;

        ul {
            display: grid;
            gap: 10px;
            li {
                display: grid;
                grid-template-columns: 5px 1fr;
                align-items: center;
                gap: 10px;
                font: 14px "GothamMedium", sans-serif;
                letter-spacing: -0.23px;
                color: #3F544E;
                &::before {
                    content: "•";
                    font-size: 14px;
                    margin-bottom: -2px;
                    position: relative;
                }
            }
        }
    }

}