body {

    @media (max-width: 600px) and (min-width: 0px) {

        background-color: #ffffff !important;
    }
}

ul {
    list-style-type: none;
}

textarea {
    padding: 13px 18px;
    border-radius: 8px;
    border: solid 1px #d5dfdb;
    background-color: #ffffff;
    font: 10px GothamMedium, sans-serif;
    font-weight: 500;
    color: #666666;
}

input,
select {
    padding: 13px 18px;
    height: 38px;
    border-radius: 8px;
    border: solid 1px #d5dfdb;
    background-color: #ffffff;
    font: 10px GothamMedium, sans-serif;
    font-weight: 500;
    color: #666;
    transition: all .2s ease-in-out;

    &::placeholder {
        color: #999;
    }

    &:hover {
        border-color: #b3ccc2;
    }

    &:focus,
    &:active {
        border-color: #b3ccc2;
        box-shadow: 0 0 0 2px rgba(179, 204, 194, 0.2);
    }
}

input,
textarea {
    resize: none;
}

textarea {
    &::placeholder {
        color: #999;
    }
}

select {
    padding: 10px 35px 10px 18px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("../assets/images/v.svg");
    background-repeat: no-repeat;
    background-position: right 20px center;
    background-size: 10px 6px;
    font: 11px GothamMedium, sans-serif;
    font-weight: 500;
    color: #999;

    option {
        padding: 10px 18px;
    }

}

:where(.css-dev-only-do-not-override-26rdvq).ant-picker:hover,
:where(.css-dev-only-do-not-override-26rdvq).ant-picker-focused {
    border-color: #b3ccc2 !important;
    box-shadow: 0 0 0 2px rgba(179, 204, 194, 0.2) !important;
}

// .ant-picker-focused {
//     border-color: none !important;
//     > input:focus {
//         border-color: none !important;
//     }
// }
// :where(.css-dev-only-do-not-override-26rdvq).ant-picker .ant-picker-input >input:focus, :where(.css-dev-only-do-not-override-26rdvq).ant-picker .ant-picker-input >input-focused {

//     border-color: #b3ccc2 !important;
//     box-shadow: 0 0 0 2px rgba(179, 204, 194, 0.2) !important;
// }
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
    top: -1px;
    border-bottom-color: #d5dfdb !important;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
    border-top: none;
    border-bottom-color: #06b7a7 !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected,
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    background-color: #04b7a7 !important;
}

// valleir
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
    background-color: #00CBB9 !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    font: 18px "GothamBold", sans-serif;
    color: #fff;
}

.react-datepicker__day-name {
    color: #fff;
}

.react-datepicker__navigation:hover {
    span::before {
        border-color: #000 !important;
    }
}


.react-datepicker__navigation-icon::before {
    border-color: #5f5f5f;
}

.react-datepicker {
    border: solid 1px #d5dfdb !important;
}

.react-datepicker__header,
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    background: #06b7a7;
}

.react-datepicker-wrapper {
    height: 38px;
    background: #fff;
}

.date-picker-custom-field {
    width: 100%;
    height: 38px;
    display: flex;
    flex-direction: row;
    padding: 12px 11px 12px 20px;
    background-color: #fff;
    border-radius: 8px;
    border: solid 1px #d5dfdb;
    align-items: center;
    justify-content: space-between;

    .value-style {
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
        margin-left: -4px;
        font: 12px "GothamMedium", sans-serif;
        color: #666;
    }

    .placeholder-style {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        font: 12px "GothamMedium", sans-serif;
        color: #999;
    }
}


.popup-geral {
    display: none;

    .modal-dialog {
        margin-top: 5%;

        .modal-content {
            width: 500px;
            padding: 26px 25px;
            border-radius: 10px;
            background-color: #ffffff;
            border: none;

            .titulo {
                margin-bottom: 20px;

                h4 {
                    font: 25px GothamBlack, sans-serif;
                    color: #000;
                    display: grid;
                    grid-template-columns: 1fr 18px;
                    gap: 10px;

                    .btn-fechar {
                        border: none;
                        background: none;
                        float: right;
                        margin-top: 2px;
                        font: 18px GothamBold, sans-serif;
                        cursor: pointer;

                        img {
                            width: 16px;
                            height: 16px;
                        }
                    }
                }
            }

            ul {
                display: grid;
                grid-gap: 19px;
                gap: 19px;
                margin-bottom: 20px;

                li {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    grid-gap: 30px;
                    gap: 30px;

                    .arq-adicionado {
                        display: grid;
                        grid-gap: 6px;
                        gap: 6px;
                    }

                    .btn-salvar {
                        width: 100%;

                        button {
                            width: 100%;
                        }
                    }

                    .intemA {
                        width: 30%;
                        text-align: right;
                    }

                    .intemB {
                        width: 70%;
                        text-align: left;
                        font: 12px 'GothamBold', sans-serif;
                        color: #3f544e;
                        word-break: break-word;

                        textarea,
                        select,
                        input {
                            width: 80%;
                        }

                        .textError {
                            margin-bottom: -14px;
                            margin-top: 4px;
                            position: absolute;
                        }

                        p {
                            width: 80%;
                            word-break: break-word;
                        }

                        a {
                            width: 79%;
                            font: 12px 'GothamBold', sans-serif;
                            color: #3f544e;
                            word-break: normal;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            // display: grid;
                            text-decoration: underline !important;

                            span {
                                overflow: hidden;
                                text-overflow: ellipsis;
                                width: 100%;

                            }
                        }

                        .imgDocLabel {
                            width: 235px;
                            padding-left: 10px;

                            span {
                                margin-right: 15px;
                            }
                        }

                        .infor-doc-dropzone {
                            margin-top: -10px;
                        }

                        .documento-anexado {
                            width: 235px;
                            padding-left: 10px;
                        }
                    }
                }
            }

            .carregando {
                display: block;
                width: 100%;
                text-align: center;
            }

            .btnExclui {
                width: 100% !important;
                text-align: center !important;

                button {
                    color: #e95656;
                    height: 20px;
                    border: none;
                    background: none;
                    margin-top: 12px;
                    font-size: 10px;
                }
            }

            .nenhum-arq {
                p {
                    font: 14px GothamMedium, sans-serif;
                    color: #fe8c60;
                    word-break: break-word;
                }
            }

            .arquivoAnexado {
                width: 100%;

                h5 {
                    font: 14px GothamBlack, sans-serif;
                    color: #000;
                    margin-bottom: 20px;
                }

                .arquivo {
                    display: grid;
                    grid-template: "nameArquivo btnExclui"/1fr 100px;
                    grid-gap: 20px;
                    margin-bottom: 15px;
                    word-break: break-word;

                    .nomeArquivo {
                        grid-area: nameArquivo;
                        justify-content: flex-start;
                        align-items: center;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        max-width: 62%;

                        a {
                            font: 12px GothamMedium, sans-serif;
                            color: #3f544e;
                            word-break: break-word;

                            text-decoration: underline !important;
                        }
                    }

                    .btnExclui {
                        grid-area: btnExclui;
                        font: 12px GothamBold, sans-serif;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        border: none;
                        background: none;
                        color: #e95656;
                    }
                }
            }

            .arquivo {
                display: grid;
                grid-template: "nameArquivo btnExclui"/1fr 100px;
                grid-gap: 20px;
                margin-bottom: 15px;

                .nomeArquivo {
                    grid-area: nameArquivo;
                    font: 12px GothamMedium, sans-serif;
                    color: #3f544e;
                    justify-content: flex-start;
                    align-items: center;

                    a {

                        text-decoration: underline !important;
                    }
                }

                .remover {
                    grid-area: btnExclui;
                    cursor: pointer;
                    font: 12px GothamBold, sans-serif;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    border: none;
                    background: none;
                    color: #e95656;
                }
            }

            .nenhum-cadastrado-perfil {
                p {
                    font: 14px GothamMedium, sans-serif;
                    color: #fe8c60;
                }
            }

            .btn-salvar {
                margin-top: 10px;

                button {
                    width: 80%;

                }
            }

            .textError {
                font: 10px GothamMedium, sans-serif;
                color: #e95656 !important;
                margin-top: 7px;
                margin-bottom: 10px;
            }
        }
    }
}

.inputErro {
    border-color: #e95656 !important;
}

.textError {
    font: 10px GothamMedium, sans-serif;
    color: #e95656 !important;
    margin-top: 42px;
    margin-bottom: -18px;
}

a {
    text-decoration: none !important;
    transition: 0.2s;
}

p,
label {
    margin-bottom: 0;
}

.btnGray {
    height: 42px;
    min-width: 110px;
    padding: 8px 12px;
    border-radius: 8px;
    background-color: #e6ecec;
    font: 12px GothamBold, sans-serif;
    color: #3f544e;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.4s;

    &:hover {
        background-color: #dadede;
    }
}

.btnRed {
    height: 42px;
    min-width: 110px;
    padding: 8px 12px;
    border-radius: 8px;
    font: 12px GothamBold, sans-serif;
    color: #fff;
    background: #e95656;
    transition: 0.2s ease-in-out;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.4s;

    &:hover {
        background: #c74343 !important;
    }

    &.load {
        &::before {
            content: "";
            background: url('../assets/images/carregamento-branco.gif');
            background-repeat: no-repeat;
            width: 13px;
            height: 13px;
            margin-left: -5px;
            background-size: cover;
            display: inline-flex;
            justify-content: flex-end;
            margin-right: 9px;
            float: left;
        }

    }
}

.btnGreen {
    height: 42px;
    min-width: 110px;
    padding: 0 20px;
    display: grid;
    align-items: center;
    border-radius: 8px;
    text-align: center;
    background-color: #04b7a7;
    border: none;
    font: 12px GothamMedium, sans-serif;
    color: #ffffff;
    transition: 0.2s;
    justify-content: center;

    &:hover {
        background-color: #00CBB9;
        color: #fff;
    }

    &.more {
        grid-template-columns: 15px 1fr;
        gap: 10px;
        text-align: left;

        &::before {
            content: '';
            float: left;
            // display: inline-block;
            width: 15px;
            height: 15px;
            // margin: 0 0 0 0;
            background-image: url("../assets/images/add-circle.svg");
            background-size: 15px 15px;
            background-repeat: no-repeat;
        }
    }

    &.load {
        grid-template-columns: 15px 1fr;
        justify-content: center;
        gap: 10px;

        &::before {
            content: "";
            background: url('../assets/images/carregamento-branco.gif');
            background-repeat: no-repeat;
            width: 13px;
            height: 13px;
            margin-left: -5px;
            background-size: cover;
            display: inline-flex;
            justify-content: flex-end;
            margin-right: 9px;
            float: left;
        }
    }
}

.btnGrayLoad {
    height: 42px;
    padding: 8px 12px;
    border-radius: 8px;
    background-color: #dadede;
    font: 11px GothamBold, sans-serif;
    color: #3f544e;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.4s;
    padding-left: 30px;

    &::before {
        content: "";
        background: url('../assets/images/carregamento-branco.gif');
        background-repeat: no-repeat;
        width: 13px;
        height: 13px;
        margin-left: -5px;
        background-size: cover;
        display: inline-flex;
        justify-content: flex-end;
        margin-right: 9px;
        float: left;
    }

}

.btnGreenLoad {
    padding: 8px 17px;
    border-radius: 8px;
    color: #fff;
    background-color: #04b7a7;
    border: none;
    font: 11px GothamMedium, sans-serif;
    color: #ffffff;
    transition: 0.2s;
    padding-left: 28px;

    &::before {
        content: "";
        background: url('../assets/images/carregamento-branco.gif');
        background-repeat: no-repeat;
        width: 13px;
        height: 13px;
        margin-left: -12px;
        background-size: cover;
        display: inline-flex;
        justify-content: flex-end;
        margin-right: -14px;
        float: left;
    }
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: none !important;
    background: none !important;
}

.dropdown-icon {
    .dropdown-toggle {
        background: none;
        border: none;
        padding: 0;
        height: 30px;
        width: 30px;

        &::after {
            display: none;
        }

        img {
            margin-top: -4px;
        }

        &:focus,
        &::selection {
            box-shadow: none;
            background: none;
        }
    }

    .dropdown-menu {
        width: 215px;
        // inset: 0px auto auto 0px !important;
        border: solid 1px #d5dfdb !important;
        box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.07);
        border-radius: 8px;
        padding: 20px 20px 3px 23px;

        a {
            font-size: 12px;
            color: #666666;
            margin-bottom: 20px;
            padding: 0;
            border: 0;
            background: none;
            transition: 0.2s ease-in-out;

            &:hover {
                color: #000;
            }

            display: block;
        }

        button {
            font-size: 12px;
            color: #666666;
            margin-bottom: 20px;
            padding: 0;
            border: 0;
            background: none;
            transition: 0.2s ease-in-out;

            &:hover {
                color: #000;
            }

            display: block;
        }

        .red {
            color: #e95656 !important;
            transition: 0.2s ease-in-out;

            &:hover {
                color: #c74343 !important;
            }
        }
    }
}

.button-toggle {
    width: 40px;
    height: 24px;
    display: flex;
    justify-content: flex-end;
    background: #E9E9EA;
    align-items: center;
    border: none;
    border-radius: 40px;
    padding: 0 1px;
    transition: 0.4s;

    span {
        width: 22px;
        height: 22px;
        border-radius: 60%;
        background: #fefefe;
    }

    &.active {
        background: #34c759;
        justify-content: flex-start;

    }

    &.load {
        span {
            background: url('../assets/images/carregando.gif');
            background-size: 22px 22px;
        }
    }

    &.load.active {
        span {
            background: url('../assets/images/carregamento-branco.gif');
            background-size: 22px 22px;
        }
    }
}

.semImgCol {
    background: #F1F1F1;
    border: 1px solid #97979797;
    border-radius: 60%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 40%;
    }
}

.imgDocLabel {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 38px;
    border-radius: 6px;
    border: solid 2px #d5dfdb;
    border-style: dashed;
    background-color: #f3f7f6;
    font: 12px GothamMedium, sans-serif;
    color: #5c6067;
    font-weight: 300;
    cursor: pointer;
    margin-bottom: 0;
    transition: 0.2s;

    p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 70%;

    }

    span {
        height: 21px;
        width: 18px;
        background: url("../assets/images/docs-upload.svg");
        background-size: 18px 21px;
        margin-bottom: -1px;
        margin-right: 15px;
        display: inline-table;
        transition: 0.2s;
    }

    &:hover {
        background: #eefff8;
        border-color: #2bc78b;
        color: #2bc78b;
        box-shadow: 0 0 6px 0 rgba(43, 199, 139, 0.3);

        span {
            height: 21px;
            width: 18px;
            background: url("../assets/images/docs-upload-green.svg");
            background-size: 18px 21px;
        }
    }

}

.imgDocLabel-error {
    .imgDocLabel {
        border: solid 2px #e95656;
        border-style: dashed;
    }
}

.inforDocAdd {
    font: 9px GothamMedium, sans-serif;
    color: #3f544e;
    margin-top: 6px;
}

.imgDocInput {
    display: none;
}

.paginacao-componente {
    margin-top: 20px;

    .ant-pagination-item-active {
        background-color: #e6ecec;
        border: none;
        color: #000;
    }

    .ant-pagination-prev {
        margin-right: 20px !important;
    }

    .ant-pagination-next {
        margin-left: 20px !important;

    }

    li {
        font: 12px "GothamMedium", sans-serif;
        color: #3f544e;

    }



    @media (max-width: 600px) and (min-width: 0px) {
        text-align: center;
        padding-left: 30px;
        padding-right: 30px;

        .ant-pagination-prev {
            display: block;
            margin-bottom: 10px;
            margin-right: 0 !important;
        }

        .ant-pagination-next {
            display: block;
            margin-top: 10px;
            margin-left: 0 !important;

        }

    }

}

.paginacaoG {
    grid-template-columns: 1fr;
    overflow: auto;

    ul {
        display: flex;

        .numberPaginate-div {
            display: flex;
            overflow: hidden;
        }

        .number {


            @media (max-width: 600px) and (min-width: 0px) {
                display: none;

            }

            button {
                width: 24px;
                height: 26px;
                background: none;
                border: none;
                font: 12px GothamMedium, sans-serif;
                color: #3f544e;
                background: #fff;
                margin-left: 13px;
                border-radius: 4px;
                transition: 0.2s;

                &:hover {
                    background: #e6ecec;
                    color: #000;

                }
            }

            .secao {
                background: #e6ecec;
                color: #000;
            }
        }

        .page-proxima,
        .page-anterior {
            padding-top: 1px;

            button {
                background: none;
                border: none;
                font: 12px GothamMedium, sans-serif;
                color: #3f544e;
                height: 24px;
                display: flex;
                align-items: center;
                transition: 0.2s;

                &:hover {
                    color: #000;
                }
            }
        }

        .page-anterior {
            margin-left: 45px;
            margin-right: 34px;

            @media (max-width: 600px) and (min-width: 0px) {
                margin-left: 26px;
            }

            button {
                &::before {
                    content: "";
                    background: url('../assets/images/arrow-left2.svg');
                    background-repeat: no-repeat;
                    width: 4px;
                    height: 17px;
                    display: inline-block;
                    margin-right: 7px;
                    margin-top: 8.4px;
                }

            }

        }

        .page-proxima {
            button {
                &::after {
                    content: "";
                    background: url('../assets/images/arrow-right.svg');
                    background-repeat: no-repeat;
                    width: 4px;
                    height: 17px;
                    display: inline-block;
                    margin-left: 7px;
                    margin-top: 8.4px;
                }
            }
        }
    }

}

.paginacao {
    ul {
        display: flex;

        .secao {
            background: #e6ecec;
            color: #000;
        }

        .page-ativa {
            button {
                width: 26px;
                height: 24px;
                border-radius: 4px;
                background-color: #e6ecec;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                font: 12px GothamMedium, sans-serif;
                color: #000;
                border: 0;
                /*background: none;*/
            }
        }

        .page {
            a {
                width: 26px;
                height: 24px;
                border-radius: 4px;
                display: inline-flex;
                justify-content: center;
                background: #f8faf9;
                align-items: center;
                font: 12px GothamMedium, sans-serif;
                color: #3f544e;
                transition: 0.2s;

                &:hover {
                    background-color: #e6ecec;
                }
            }
        }

        .page-anterior {
            button {
                font: 12px GothamMedium, sans-serif;
                color: #3f544e;
                height: 24px;
                padding-left: 10px;
                padding-right: 10px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                margin-left: 0px;
                border: 0;
            }

            button:hover {
                background-color: #e6ecec;
            }
        }

        .page-proxima {
            button {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                height: 24px;
                padding-left: 10px;
                padding-right: 10px;
                font: 12px GothamMedium, sans-serif;
                color: #3f544e;
                margin-left: 14px;
                border: 0;
                /*background: none;*/
            }

            button:hover {
                background-color: #e6ecec;
            }
        }
    }
}

.modalGeral {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1050;
    background-color: rgba(0, 0, 0, 0.4);
    overflow-x: hidden;
    overflow-y: auto;

    .boxModal {
        width: 500px;
        padding: 26px 25px;
        border-radius: 10px;
        background-color: #ffffff;
        margin: 0 auto;
        margin-top: 5%;
        margin-bottom: 5%;

        .radio {
            display: flex;
            align-items: center;
            height: 38px;

            .chackboxLabel {
                cursor: pointer;
                width: 18px;
                height: 18px;
                border-radius: 3px;
                border: solid 1px #c1d0ca;
                margin-bottom: 0;
                margin-right: 10px;
                border-radius: 60%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .foreignTexto {
                margin-bottom: 0;
                margin-right: 15px;
                cursor: pointer;
                margin-top: 2px;
            }

            input {
                display: none;
            }

            input[type=radio]:checked+.chackboxLabel {
                border: solid 1px #04b7a7;

                span {
                    width: 10px;
                    height: 10px;
                    display: block;
                    background-color: #04b7a7;
                    border-radius: 60%;
                }
            }
        }

        .titulo {
            margin-bottom: 20px;

            h4 {
                font: 25px GothamBlack, sans-serif;
                color: #000;
                margin-bottom: 15px;

                .btn-fechar {
                    border: none;
                    background: none;
                    float: right;
                    padding: 5px 5px 1px;
                    margin-right: -5px;
                    margin-top: -1px;
                    font: 18px GothamBold, sans-serif;
                    cursor: pointer;
                }
            }
        }

        ul {
            display: grid;
            grid-gap: 19px;
            gap: 19px;
            margin-bottom: 20px;

            li {
                display: flex;
                align-items: center;
                width: 100%;
                grid-gap: 30px;
                gap: 30px;

                .intemA {
                    width: 30%;
                    text-align: right;
                }

                .intemB {
                    width: 70%;
                    text-align: left;
                    font: 12px 'GothamBold', sans-serif;
                    color: #3f544e;

                    form,
                    select,
                    input {
                        width: 80%;
                    }

                    .textError {
                        margin-bottom: -9px;
                    }

                    .imgDocLabel {
                        width: 235px;
                        padding-left: 10px;

                        span {
                            margin-right: 15px;
                        }
                    }

                    .documento-anexado {
                        width: 235px;
                        padding-left: 10px;
                    }
                }
            }
        }

        .btn-salvar {
            text-align: center;
            margin-top: 20px;

            button {
                width: 100%;

            }
        }
    }
}

.infor-doc-dropzone {
    font: 9px 'GothamMedium', sans-serif;
    color: #3f544e;
}

/////////////////////////////////
.swal-footer,
.swal-text {
    text-align: center;
}

//////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////

.box-finalizado {
    height: 70%;
    padding: 36px 40px 40px 44px;
    border-radius: 8px;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.07);
    border: solid 1px #d5dfdb;
    background-color: #ffffff;
    text-align: center;

    @media (max-width: 600px) and (min-width: 0px) {
        padding: 30px 30px 60px 30px;
        border: none;
        box-shadow: none;

    }

    .icone {
        max-width: 200px;
        max-height: 200px;
        margin: 0 auto;
        margin-bottom: 33px;

    }

    h2 {
        font: 22px 'GothamBold', sans-serif;
        color: #2bc78b;
        margin-bottom: 20px;
    }

    p {
        font: 12px 'GothamMedium', sans-serif;
        color: #8c939c;
        max-width: 400px;
        margin: 0 auto;
        margin-bottom: 33px;
    }

    .liberar-acesso {
        max-width: 250px;
        margin: 0 auto;
        margin-bottom: 20px;
    }

    .voltar {
        width: auto;
        font: 12px 'GothamMedium', sans-serif;
        color: #8c939c;

        &::before {
            content: "";
            background: url(../assets/images/arrow-left2.svg);
            background-repeat: no-repeat;
            background-size: contain;
            width: 8px;
            height: 14px;
            margin-bottom: -4px;
            display: inline-block;
            margin-right: 12px;
        }
    }

    a {
        max-width: 250px;
        margin: 0 auto;

        &:hover {
            color: #3f544e;
        }
    }

    @media (max-width: 1500px) and (min-width: 0px) {

        height: 60%;
        min-height: 436px;

        .icone {
            width: 150px;
            height: 150px;
        }
    }
}

.carregamento {
    text-align: center;

    img {
        width: 40px !important;
        height: 40px !important;
    }
}

///////////// Mensagem de erro e sucesso
.swal-icon {
    margin: 25px auto;
}

.swal-icon--success~.swal-title {
    font: 22px 'GothamBlack', sans-serif !important;
    color: #2bc78b !important;
    margin-bottom: 20px;
    padding: 0;
}

.swal-text {
    font: 16px 'GothamMedium', sans-serif !important;
    color: #8c939c !important;
}

.swal-overlay {
    background-color: rgba(0, 0, 0, .5);
}

.swal-footer {
    padding-bottom: 43px;
    // display: flex;
    // flex-direction: row;
    // align-content: center;

    .swal-button-container {
        margin: 0 5px;
        // .swal-button--confirm {
        //     padding: 7px 19px;
        //     border-radius: 2px;
        //     background-color: #4962B3;
        //     font-size: 12px;
        //     border: 1px solid #3e549a;
        //     text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
        // }
        // .swal-button--abort {
        //     padding: 7px 19px;
        //     border-radius: 2px;
        //     background-color: #08f1f1;
        // }
        .swal-button {
            width: 225px;
            height: 42px;
            background-color: #e6ecec;
            font: 12px 'GothamBold', sans-serif !important;
            color: #3f544e;
            transition: 0.4s;

            &:hover {
                background-color: #dadede;
            }
        }
    }
}

.swal-icon--success {
    // display: none;
    border: none;
    width: 150px;
    height: 150px;

    &:first-child {
        margin-top: 73px;
    }

    &::before {
        display: none;
    }

    .swal-icon--success__hide-corners,
    .swal-icon--success__ring,
    .swal-icon--success__line--tip,
    .swal-icon--success__line--long {
        display: none;
    }

    &::after {
        content: '';
        background-image: url(../assets/images/success.svg);
        transform: none;
        border-radius: none;
        animation: none;
        transform-origin: none;
        background-size: cover;
        width: 150px;
        height: 150px;
        left: 0;
        top: 0;
    }
}

.swal-icon--error~.swal-title {
    font: 22px 'GothamBlack', sans-serif !important;
    color: #e95656 !important;
    margin-bottom: 20px;
    padding: 0;

}

.swal-icon--error {
    border: none;
    width: 150px;
    height: 150px;

    &:first-child {
        margin-top: 73px;
    }

    &::before {
        display: none;
    }

    .swal-icon--error__line {
        display: none;
    }

    &::after {
        content: '';
        background-image: url(../assets/images/error.svg);
        transform: none;
        border-radius: none;
        animation: none;
        transform-origin: none !important;
        background-size: cover;
        width: 150px;
        height: 150px;
        display: inline-block;
        left: 0;
        top: 0;
    }
}

.swal-icon--warning~.swal-title {
    font: 22px 'GothamBlack', sans-serif !important;
    color: #f8bb86 !important;
    margin-bottom: 20px;
    padding: 0;

}

.swal-icon--warning~.swal-footer {

    .swal-button {
        width: 200px;
    }
}

.campo-busca {
    font: 13px 'GothamMedium', sans-serif;
    width: 100%;
    padding: 11px 10px 10px 45px;
    background: url("../assets/images/search.svg") 1rem 0.8rem no-repeat;
    background-color: white;
    letter-spacing: -0.2px;
    background-position: 1rem 0.75em;
}

/////////////////////////////////////////
////////////// Header geral ////////////
.header-geral {
    display: grid;
    min-height: 110px;
    padding-top: 30px;
    padding-bottom: 28px;
    align-items: center;
    grid-template-columns: 1fr 2fr;
    grid-gap: 0;
    gap: 0;

    .cabeçalho {
        h1 {
            font: 31px GothamBlack, sans-serif;
            color: #000;
            letter-spacing: -0.52px;
            margin-bottom: 0;
            display: flex;
            align-items: center;

            .voltar {
                font: 14px GothamMedium, sans-serif;
                color: #666;
                display: inline-block;
                align-items: center;
                letter-spacing: -0.23px;
                margin-right: 30px;
                width: 63px;

                &::before {
                    content: "";
                    background: url('../assets/images/arrow-left3.svg');
                    background-repeat: no-repeat;
                    width: 8px;
                    height: 14px;
                    margin-bottom: -2px;
                    display: inline-block;
                    margin-right: 12px;
                    // margin-top: 8.4px;
                }
            }
        }

        p {
            font: 14px GothamMedium, sans-serif;
            letter-spacing: -0.23px;
            color: #666;
            margin-top: 8px;
        }
    }

    .infor {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .icon-infor {
            border: none;
            background: none;
            margin-left: 42px;
        }

        .dropdown-notificacao {
            margin-left: 40px;

            .bolinha-notificacao {
                width: 10px;
                height: 10px;
                background: #E95656;
                display: inline-block;
                border-radius: 60%;
                z-index: 10;
                position: absolute;
                right: 3px;
                top: 5px;
            }

            .dropdown-menu {
                width: 280px;
                // transform: translate(-250px, 32px) !important;
                padding: 19px 18px 3px 18px;
            }

            .header-notif {
                display: grid;
                grid-template-columns: 1fr 123px;
                border-bottom: solid 1px #d5dfdb;
                height: 26px;
                align-content: flex-start;

                p {
                    font: 14px "GothamBold", sans-serif;
                    letter-spacing: -0.2px;
                    color: #8C939C;
                    margin-bottom: 0;
                }

                button {
                    font: 11px "GothamBold", sans-serif;
                    letter-spacing: -0.18px;
                    color: #327EE4;
                    margin-bottom: 0;
                }
            }

            .carregamento {
                padding-top: 15px;
                text-align: center;
                padding-bottom: 15px;
            }

            .lista-notif {
                display: grid;
                gap: 10px;
                padding-top: 10px;
                max-height: 310px;
                overflow-y: auto;
                position: relative;

                a {
                    margin-bottom: 0;
                }

                .notificacao-normal.nao-visualizado,
                .adicionou-ao-evento.nao-visualizado {
                    background-color: #EAF2F3;
                    border-bottom: solid 1px #EAF2F3;
                    border-radius: 8px;
                }

                .notificacao-normal.visualizado,
                .adicionou-ao-evento.visualizado {
                    background-color: #fff;
                    border-bottom: solid 1px #d5dfdb;
                    transition: all .2s ease-in-out;

                    &:hover {
                        background-color: #EAF2F3;
                        border-bottom: solid 1px #EAF2F3;
                        border-radius: 8px;
                    }
                }

                .notificacao-normal {
                    display: grid;
                    grid-template-columns: 35px 1fr;
                    gap: 10px;
                    padding: 9px 10px 12px 10px;

                    .colSemFoto {
                        width: 35px;
                        height: 35px;

                        .semImgCol {
                            width: 35px;
                            height: 35px;
                        }
                    }

                    .img-col {
                        width: 35px;
                        height: 35px;
                        border-radius: 60%;

                        img {
                            width: 35px;
                            height: 35px;
                            border-radius: 60%;
                        }
                    }

                    .nome-data {
                        display: grid;
                        grid-template-columns: 1fr 50px;
                        margin-bottom: 7px;

                        p {
                            font: 11px "GothamBold", sans-serif;
                            letter-spacing: -0.18px;
                            color: #000000;

                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                            max-width: 90%;
                        }

                        span {
                            text-align: right;
                            font: 10px "GothamMedium", sans-serif;
                            letter-spacing: -0.16px;
                            color: #8C939C;
                        }
                    }

                    .nome-evento {
                        font: 11px "GothamMedium", sans-serif;
                        letter-spacing: -0.18px;
                        color: #8C939C;
                        margin-bottom: 2px;
                    }

                }

                .adicionou-ao-evento {
                    display: grid;
                    grid-template-columns: 35px 1fr;
                    gap: 10px;
                    padding: 9px 10px 12px 10px;

                    .colSemFoto {
                        width: 35px;
                        height: 35px;

                        .semImgCol {
                            width: 35px;
                            height: 35px;
                        }
                    }

                    .img-col {
                        width: 35px;
                        height: 35px;
                        border-radius: 60%;

                        img {
                            width: 35px;
                            height: 35px;
                            border-radius: 60%;
                        }
                    }

                    .infor-vento {
                        .nome-data {
                            display: grid;
                            grid-template-columns: 1fr 50px;
                            margin-bottom: 7px;

                            p {
                                font: 11px "GothamBold", sans-serif;
                                letter-spacing: -0.18px;
                                color: #000000;

                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                                max-width: 90%;
                            }

                            span {
                                text-align: right;
                                font: 10px "GothamMedium", sans-serif;
                                letter-spacing: -0.16px;
                                color: #8C939C;
                            }
                        }

                        .add-evento {
                            font: 11px "GothamMedium", sans-serif;
                            letter-spacing: -0.18px;
                            color: #8C939C;
                            margin-bottom: 2px;
                        }

                        .nome-evento {
                            font: 11px "GothamMedium", sans-serif;
                            letter-spacing: -0.18px;
                            color: #000;
                        }

                    }
                }
            }

            .btn-vermais {
                text-align: center;
                padding-top: 10px;
                padding-bottom: 15px;

                button {
                    padding: 5px;
                    margin: 0 auto;

                }
            }
        }

        .dropdown-user {
            margin-left: 40px;

            .dropdown-toggle {
                img {
                    margin-bottom: -2px;
                }
            }

            .dropdown-menu {
                // transform: translate(-180px, 36px) !important;
                width: 215px !important;

                .infor-col {
                    display: grid;
                    grid-template-columns: 30px 1fr;
                    align-items: center;
                    gap: 10px;
                    margin-bottom: 20px;

                    .colSemFoto {
                        width: 30px;
                        height: 30px;

                        .semImgCol {
                            width: 30px;
                            height: 30px;

                        }
                    }

                    .img-col {
                        width: 30px;
                        height: 30px;
                        border-radius: 60%;

                        img {
                            width: 30px;
                            height: 30px;
                            border-radius: 60%;
                        }
                    }

                    .nome {
                        font: 14px "GothamBold", sans-serif;
                        letter-spacing: -0.23px;
                        color: #000000;
                        margin-bottom: 0;
                    }
                }

            }
        }
    }
}

/////////////////////////////////////////
/////////////////////////////////////////
.inputTime {
    height: 38px;
    padding: 0;

    input {
        height: 38px;
    }

    .ant-picker-clear,
    .ant-picker-suffix {
        display: none !important;
    }

}

input[type='time']::-moz-clear-button,
input[type="time"]::-webkit-clear-button {
    display: none;
    position: absolute;
    left: -1000000000px;
}

input[type='time']::-moz-inner-spin-button,
input[type="time"]::-webkit-inner-spin-button {
    display: none;
}

input[type='time']::-webkit-calendar-picker-indicator {
    background: none;
    display: none !important;
}

.btn-radio {
    border: none;
    background: none;
    padding: 0;
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;

    .chekboxLabel {
        display: inline-block;
        width: 19px;
        height: 19px;
        border: 1px solid #cfd9e6;
        border-radius: 50%;
        background-color: #fff;
        transition: all .2s ease-in-out;
        cursor: pointer;
    }

    .texto {
        font: 11px "GothamBold", sans-serif;
        color: #333;
        display: flex;
        text-align: left;
        height: 100%;
        align-items: center;
        cursor: pointer;
        padding: 4px 0 3px;
    }

    &:hover {
        .chekboxLabel {
            border: 1px solid #04b7a7;
            display: flex;
            align-items: center;
            justify-content: center;

            &::after {
                content: '';
                display: inline-block;
                width: 9px;
                height: 9px;
                border-radius: 50%;
                background-color: #04b7a7;
                opacity: 100;
            }
        }
    }

    .chekboxLabel.checado {
        border: 1px solid #04b7a7;
        display: flex;
        align-items: center;
        justify-content: center;

        &::after {
            content: '';
            display: inline-block;
            width: 9px;
            height: 9px;
            border-radius: 50%;
            background-color: #04b7a7;
            opacity: 100;
        }
    }


}

.radioNovo {
    position: relative;
    cursor: pointer;
    width: 70px;
    display: inline-block;

    input[type=radio] {
        position: absolute;
        width: 100%;
        min-width: unset;
        height: 20px;
        opacity: 0;
        cursor: pointer;
        z-index: 999;
    }

    input[type=radio]:hover+label::after {
        content: '';
        display: inline-block;
        position: absolute;
        width: 9px;
        height: 9px;
        top: 5px;
        left: 5px;
        border-radius: 50%;
        opacity: 100;
        background-color: #04b7a7;
    }

    input[type=radio]:hover+label::before {
        content: '';
        border: 1px solid #04b7a7;
    }

    input[type=radio]:checked+label::after {
        content: '';
        display: inline-block;
        position: absolute;
        width: 9px;
        height: 9px;
        top: 5px;
        left: 5px;
        border-radius: 50%;
        background-color: #04b7a7;
        opacity: 100;
    }

    input[type=radio]:checked+label::before {
        content: '';
        border: 1px solid #04b7a7;
    }

    label {
        display: inline-block;
        user-select: none;
        line-height: 21px;
        font: 11px GothamBold, sans-serif;
        color: #333;

        &::before {
            content: '';
            display: inline-block;
            width: 19px;
            height: 19px;
            border: 1px solid #cfd9e6;
            border-radius: 50%;
            margin: 0 10px -5px 0;
            background-color: #fff;
            transition: all .2s ease-in-out;
        }

        &::after {
            transition: all .2s ease-in-out;
            opacity: 0;
        }
    }
}

.chekboxText {
    position: relative;

    input[type=checkbox] {
        width: 84%;
        min-width: unset;
        height: 20px;
        position: absolute;
        opacity: 0;
        cursor: pointer;
        z-index: 999;

        label {
            transition: .1s ease;
        }
    }

    input[type=checkbox]:hover+label {
        color: #5d5d5d;

        &::before {
            border: 1px solid #c1d0ca;
        }
    }

    input[type=checkbox]:checked+label::after {
        content: '';
        display: inline-block;
        position: absolute;
        width: 11px;
        height: 8px;
        border: none;
        left: 5px;
        top: 6px;
        background-image: url("../assets/images/check.svg");
        background-size: 11px 8px;
        background-repeat: no-repeat;
    }

    label {
        display: block;
        font-size: 13px;
        color: #333333;
        user-select: none;

        &::before {
            content: '';
            display: inline-block;
            width: 20px;
            height: 20px;
            border: 1px solid #cfd9e6;
            border-radius: 3px;
            margin: 0 10px -5px 0;
            transition: all .15s ease-in-out;
        }

        &::after {
            display: none;
        }
    }
}

.chekboxNovo {
    position: relative;

    input[type=radio],
    input[type=checkbox] {
        width: 84%;
        min-width: unset;
        height: 20px;
        position: absolute;
        opacity: 0;
        cursor: pointer;
        z-index: 999;

        label {
            transition: .1s ease;
        }
    }

    input[type=radio]:hover+label,
    input[type=checkbox]:hover+label {
        color: #5d5d5d;

        &::before {
            border: 1px solid #c1d0ca;
        }
    }

    input[type=radio]:checked+label::after,
    input[type=checkbox]:checked+label::after {
        content: '';
        display: inline-block;
        position: absolute;
        width: 11px;
        height: 8px;
        border: none;
        left: 5px;
        top: 6px;
        background-image: url("../assets/images/check.svg");
        background-size: 11px 8px;
        background-repeat: no-repeat;
    }

    label {
        display: block;
        font-size: 13px;
        color: #333333;
        user-select: none;

        &::before {
            content: '';
            display: inline-block;
            width: 20px;
            height: 20px;
            border: 1px solid #cfd9e6;
            border-radius: 3px;
            margin: 0 10px -5px 0;
            transition: all .15s ease-in-out;
        }

        &::after {
            display: none;
        }
    }
}


.chek-button {
    display: flex;
    align-items: center;
    gap: 10px;
    font: 11px "GothamBold", sans-serif;
    letter-spacing: -0.18px;
    color: #333333;
    border: none;
    background: none;
    position: relative;
    text-align: left;

    label {
        width: 18px;
        height: 18px;
        border-radius: 3px;
        border: solid 1px #c1d0ca;
        margin-bottom: 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &.active {
        label::after {
            content: '';
            display: inline-block;
            width: 11px;
            height: 8px;
            border: none;
            background-image: url("../assets/images/check.svg");
            background-size: 11px 8px;
            background-repeat: no-repeat;
        }

    }

}

.tooltips {
    position: relative;
    cursor: pointer;

    .tooltips-infor {
        visibility: hidden;
        width: 193px;
        background-color: #EEF4F4;
        letter-spacing: -0.18px;
        font: 11px "GothamMedium", sans-serif;
        line-height: 16px;
        color: #3F544E;
        text-align: left;
        padding: 13px 19px;
        border-radius: 6px;
        /* Position the tooltip text */
        position: absolute;
        z-index: 1;
        /* Fade in tooltip */
        opacity: 0;
        transition: opacity 0.4s;
    }

    &:hover .tooltips-infor {
        visibility: visible;
        opacity: 1;
    }
}

///////////////////////////////
//////////////////////////////
.sub-menu-geral {
    li {
        display: inline-block;
        margin-right: 20px;
        position: relative;

        .not-active {
            pointer-events: none;
            cursor: no-drop !important;
        }

        a {
            font: 12px 'GothamBold', sans-serif;
            width: 100%;
            color: #999;
            display: inline-block;
            transition: 0.2s;
            margin-top: 8px;

            span {
                padding: 2px 15px;
            }

            &::after {
                content: '';
                width: 100%;
                height: 3px;
                border-radius: 3.5px;
                display: list-item;
                background-color: #fff0;
                margin-top: 4px;
                transition: 0.2s;
            }

            &:hover {
                color: #3f544e;

                &::after {
                    background-color: #04b7a7;
                }
            }
        }

        .active {
            color: #3f544e;

            &::after {
                background-color: #04b7a7;
            }
        }
    }
}


/////////////////////////////////////////
/////////// Select estilizado  //////////
////////////////////////////////////////
.select-busca {
    position: relative;

    .busca {
        width: 100% !important;
    }

    .resultado-busca {
        display: grid;
        width: 100%;
        max-height: 200px;
        padding-bottom: 10px;
        padding-top: 10px;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.07);
        border: solid 1px #d5dfdb;
        border-radius: 6px;
        background-color: #fff;
        overflow-y: auto;
        overflow-x: hidden;
        position: absolute;
        z-index: 99;

        &:focus {
            display: grid;
        }

        button {
            border: none;
            background: none;
            width: 100%;
            font: 10px 'GothamMedium', sans-serif;
            letter-spacing: -0.2px;
            color: #3F544E;
            display: grid;
            align-items: center;
            padding: 10px 10px 10px 17px;
            background-color: #fff;
            transition: all .2s ease-in-out;
            justify-content: center;
            text-align: left;

            &:hover {
                background-color: #EAF4F1;
            }
        }
    }

    // input:active  ~ .resultado-busca   {
    //     display: grid;
    // }
    .resultado-busca.busca-bank {
        button {
            grid-template-columns: 1fr;
        }
    }
}

.select-x {
    position: relative;

    .btn-select {
        width: 100%;
        padding: 10px 18px 10px 18px;
        height: 38px;
        border-radius: 8px;
        border: solid 1px #d5dfdb;
        font: 12px "GothamMedium", sans-serif;
        font-weight: 500;
        color: #666;
        transition: all .2s ease-in-out;
        background: url("../assets/images/v.svg"), #fff;
        background-repeat: no-repeat;
        background-position: right 20px center;
        background-size: 10px 6px;
        text-align: left;
        display: inline-block;
        position: relative;
        display: grid;
        align-items: center;

        span {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 90%;
            display: block;
            line-clamp: 1;
            max-height: 32px;
        }

        &::placeholder {
            color: #999;
        }

        &:hover {
            border-color: #b3ccc2;
        }

        &:focus,
        &:active {
            border-color: #b3ccc2;
            box-shadow: 0 0 0 2px rgba(179, 204, 194, 0.2);
        }
    }

    .resultado-busca {
        // display: none;
        display: grid;
        width: 100%;
        max-height: 200px;
        padding-bottom: 10px;
        padding-top: 10px;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.07);
        border: solid 1px #d5dfdb;
        border-radius: 6px;
        background-color: #fff;
        overflow-y: auto;
        overflow-x: hidden;
        position: absolute;
        z-index: 99;

        &:focus {
            display: grid;
        }

        button {
            border: none;
            background: none;
            width: 100%;
            font: 10px 'GothamMedium', sans-serif;
            letter-spacing: -0.2px;
            color: #3F544E;
            align-items: center;
            padding: 10px 17px;
            background-color: #fff;
            transition: all .2s ease-in-out;
            justify-content: center;
            text-align: left;

            &:hover {
                background-color: #EAF4F1;
            }
        }

    }
}

.cont-select {
    position: relative;

    .select- {
        width: 100%;
        padding: 10px 18px 10px 18px;
        height: 38px;
        border-radius: 8px;
        border: solid 1px #d5dfdb;
        font: 12px "GothamMedium", sans-serif;
        font-weight: 500;
        color: #666;
        transition: all .2s ease-in-out;
        background: url("../assets/images/v.svg"), #fff;
        background-repeat: no-repeat;
        background-position: right 20px center;
        background-size: 10px 6px;
        text-align: left;
        display: inline-block;
        position: relative;
        display: grid;
        align-items: center;

        p {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 90%;
            display: block;
            line-clamp: 1;
            max-height: 32px;
        }

        &::placeholder {
            color: #999;
        }

        &:hover {
            border-color: #b3ccc2;
        }

        &:focus,
        &:active {
            border-color: #b3ccc2;
            box-shadow: 0 0 0 2px rgba(179, 204, 194, 0.2);

            &~.dropDown {
                visibility: visible;
                opacity: 1;
            }
        }

        .placeholder {
            color: #999;
        }
    }


    .dropDown {
        width: 100%;
        position: relative;
        padding-bottom: 5px;
        padding-top: 5px;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.07);
        border: solid 1px #d5dfdb;
        border-radius: 6px;
        background-color: #fff;
        position: absolute;
        z-index: 99;
        margin-top: 1px;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s, opacity 0.1s linear;
    }

    .listDropDown {
        position: relative;

        &:focus {
            display: grid;
        }

        input.item,
        button.item {
            width: 100% !important;
            font: 12px 'GothamMedium', sans-serif;
            letter-spacing: -0.2px;
            color: #3F544E;
            align-items: center;
            padding: 10px 17px;
            background-color: #fff;
            transition: all .2s ease-in-out;
            justify-content: center;
            text-align: left;
            border: none;
            border-radius: 0;

            &:hover {
                background-color: #EAF4F1;
            }
        }

        input.item {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 98%;

        }

    }

}

.swal-modal {
    max-width: 478px !important;

    .swal-footer {
        @media (max-width: 487px) and (min-width: 0px) {
            .swal-button-container {
                margin-bottom: 20px;
            }
        }
    }
}

@media (max-width: 600px) and (min-width: 0px) {
    .modal.show {
        .modal-dialog {
            max-width: 100%;
            min-height: 100%;
            margin: 0;
            border-radius: 0;
            display: contents;

            .modal-content {
                width: 100%;
                height: 100%;
                border-radius: 0;
            }
        }
    }
}




.select-busca-btn {
    position: relative;
    width: 100%;

    .menu-button {
        cursor: pointer;
        width: 100%;
        padding: 10px 18px 10px 18px;
        height: 38px;
        border-radius: 8px;
        border: solid 1px #d5dfdb;
        font: 12px "GothamMedium", sans-serif;
        font-weight: 500;
        color: #666;
        transition: all .2s ease-in-out;
        background: url("../assets/images/v.svg"), #fff;
        background-repeat: no-repeat;
        background-position: right 20px center;
        background-size: 10px 6px;
        text-align: left;
        display: inline-block;
        position: relative;
        display: grid;
        align-items: center;

        span {

            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 90%;
            display: block;
            line-clamp: 1;
            max-height: 32px;

            .placeholder {
                color: #999;
            }
        }

    }

    input.menu-button {
        padding: 10px 41px 10px 18px;
    }

    .menu {
        z-index: 99;
        width: 100%;
        max-height: 200px;
        padding-bottom: 10px;
        padding-top: 10px;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.07);
        border: solid 1px #d5dfdb;
        border-radius: 6px;
        background-color: #fff;
        position: absolute;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s, opacity 0.1s linear;

        ul {
            min-height: 40px;
            margin-bottom: 0 !important;
            gap: 0 !important;

            li {
                >button {
                    border: none;
                    background: none;
                    width: 100%;
                    font: 10px 'GothamMedium', sans-serif;
                    letter-spacing: -0.2px;
                    color: #3F544E;
                    align-items: center;
                    padding: 10px 17px;
                    background-color: #fff;
                    transition: all 0.3s ease-in-out;
                    justify-content: center;
                    text-align: left;

                    &:hover {
                        background-color: #EAF4F1;
                    }

                    .collaborador {
                        display: grid;
                        grid-template-columns: 37px 1fr;
                        gap: 18px;
                        align-items: center;
                        position: relative;

                        button {
                            border: none;
                            background: none;
                            align-items: center;


                        }

                        .semImgCol {
                            height: 37px;
                        }

                        .foto {
                            img {
                                width: 100%;
                                object-fit: cover;
                                height: 37px;
                                border-radius: 60%;
                            }
                        }

                        .infor {
                            display: grid;
                        }

                        strong {
                            font: 14px 'GothamMedium', sans-serif;
                            letter-spacing: -0.23px;
                            color: #000;
                            width: 96%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;

                        }

                        p {
                            position: relative;
                            display: inline-block;
                            font: 12px 'GothamMedium', sans-serif;
                            letter-spacing: -0.2px;
                            color: #999999;
                            width: 99%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;

                        }

                    }
                }
            }
        }


    }

    .menu.ativo {
        opacity: 1;
        visibility: visible;
        transition: all 0.3s ease-in-out;
        opacity: 1;

    }
}

.select-busca-btn.col {
    padding: 0;
}

.modal.show .modal-dialog .modal-content {
    height: auto !important;
    min-height: 100%;
}

.box-leiaute {
    border-radius: 8px;
    box-shadow: 0 1px 8px 1px #eaeaea;
    border: solid 1px #d5dfdb;
    background: #ffffff;
}

.general-table {
    .header-table {
        background: #fff;
        border-radius: 8px;
        border: solid 1px #d5dfdb;
        display: grid;
        align-items: center;
        padding: 0 23px;

        p {
            font: 12px 'GothamMedium', sans-serif;
            letter-spacing: -0.2px;
            color: #000;

        }

        @media (max-width: 600px) and (min-width: 0px) {
            border-radius: 0;
            border-top: none;
            border-left: none;
            border-right: none;
            padding: 0 30px;
        }
    }

    .body-table {
        display: grid;
        margin-top: 15px;

        .item {
            height: 60px;
            background: #fff;
            border-radius: 8px;
            border: solid 1px #d5dfdb;
            display: grid;
            grid-gap: 10px;
            align-items: center;
            padding: 0 20px 0 23px;
            position: relative;

            a {
                height: 100%;
                display: flex;
                align-items: center;
            }

            .collaborador {
                display: grid;
                grid-template-columns: 37px 1fr;
                gap: 18px;
                align-items: center;
                position: relative;

                .semImgCol {
                    height: 37px;
                }

                .foto {
                    img {
                        object-fit: cover;
                        height: 37px;
                        border-radius: 60%;
                    }
                }

                .infor {
                    display: grid;
                }

                strong {
                    font: 14px 'GothamMedium', sans-serif;
                    letter-spacing: -0.23px;
                    color: #000;
                    width: 96%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                p {
                    position: relative;
                    display: inline-block;
                    font: 12px 'GothamMedium', sans-serif;
                    letter-spacing: -0.2px;
                    color: #999999;
                    width: 99%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                }

            }
        }

        @media (max-width: 600px) and (min-width: 0px) {
            margin-top: 0;

            .item {
                border-top: none;
                border-left: none;
                border-right: none;
                padding: 0 30px;
            }
        }

    }
}

.salary-input {
    background: url("../assets/images/R$.svg");
    background-repeat: no-repeat;
    background-position: 20px 12px;
    padding-left: 39px;

}