.layaut-container {
    width: 100%;
    display: flex;

    .sidebar {
        width: 250px;
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        transition: all 0.4s;
        z-index: 99;

        @media (max-width: 1100px) and (min-width: 0px) {
            left: -250px;
            border: none;
            box-shadow: 18px 0px 15px -8px rgba(0, 0, 0, 0.07);
        }

    }

    .cont-interno {
        width: calc(100% - 250px);
        padding-left: 40px;
        padding-right: 40px;
        margin-left: 250px;
        // transition: all 0.4s;

        @media (max-width: 1100px) and (min-width: 0px) {
            width: 100%;
            margin-left: 0;
        }

        .conteudo {
            z-index: 0;
            height: 100%;
        }

        .btn-menu-responsivo {
            .btn-closed-sidebar {
                left: 240px;
                transition: all 0.4s;
            }
        }


        @media (max-width: 600px) and (min-width: 0px) {
            padding-left: 0px;
            padding-right: 0px;
        }
    }


}

.layaut-sem-sidbar {
    width: 100%;

    @media (max-width: 600000px) and (min-width: 1100px) {
        display: flex;
    }

    .sidebar {
        left: -250px;
        width: 250px;
        position: fixed;
        height: 100%;
        transition: all 0.4s;
        z-index: 99;

        @media (max-width: 1100px) and (min-width: 0px) {
            box-shadow: 18px 0px 15px -8px rgba(0, 0, 0, 0.07);
            // left:0px;
        }

        @media (max-width: 600000px) and (min-width: 1100px) {
            position: none;
            left: 0;
        }

        @media (max-width: 450px) and (min-width: 0px) {
            left: -100%;
        }
    }

    .cont-interno {
        width: calc(100%);
        transition: all 0.4s;
        padding-left: 40px;
        padding-right: 40px;
        margin-left: 0;

        @media (max-width: 600000px) and (min-width: 1100px) {
            width: calc(100% - 250px);
            margin-left: 250px;
        }

        .btn-menu-responsivo {
            .btn-open-sidebar {
                left: -8px;
                transition: all 0.4s;
            }
        }

        @media (max-width: 600px) and (min-width: 0px) {
            padding-left: 0px;
            padding-right: 0px;
        }
    }
}

.layaut-container,
.layaut-sem-sidbar {
    height: 100%;

    .btn-menu-mobile {
        display: none;
        position: absolute;
        margin-top: 28px;
        padding-left: 30px;
        gap: 20px;

        button {
            border: none;
            background: none;
        }

        .logo {
            width: 140px;

            img {
                width: 140px;
            }
        }

        @media (max-width: 600px) and (min-width: 0px) {
            display: flex;
        }

        @media (max-width: 340px) and (min-width: 0px) {
            margin-top: 34px;

            .logo {
                width: 100px;

                img {
                    width: 100px;
                }
            }

        }
    }

    .sidebar {
        @media (max-width: 450px) and (min-width: 0px) {
            width: 100%;
        }
    }

    .sidebar.ativo {
        @media (max-width: 1100px) and (min-width: 0px) {
            left: 0px !important;
        }
    }

    .cont-interno {
        .conteudo {
            height: 100%;
        }

        .btn-menu-responsivo {

            .btn-open-sidebar,
            .btn-closed-sidebar {
                position: absolute;
                width: 35px;
                height: 45px;
                border: none;
                margin-top: 30px;
                border-radius: 3px;
                background: #fff;
                box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.07);
                display: flex;
                align-items: center;
                padding-left: 15px;
                transition: all 0.4s;
                z-index: 99;

                @media (max-width: 600px) and (min-width: 0px) {
                    display: none !important;
                }

                @media (max-width: 600000px) and (min-width: 1100px) {
                    display: none !important;
                }
            }
        }

        @media (max-width: 600px) and (min-width: 0px) {
            padding-left: 0;
            padding-right: 0;
        }
    }
}