.calendario-eventos {
    width: 100%;
    height: 100%;
    padding: 16px 30px 25px 29px;
    border-radius: 8px;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.07);
    border: solid 1px #d5dfdb;
    background-color: #fff;
    // display: grid;

    &>.header {
        display: grid;
        grid-template-columns: 1fr 60px 132px;
        gap: 30px;
        z-index: 9;

        .infor {
            display: grid;
            grid-template-columns: 120px 2fr;
            align-items: center;
            gap: 30px;

            @media (max-width: 1500px) and (min-width: 0px) {
                gap: 10px;
                grid-template-columns: 1fr;
            }

            h4 {
                font: 22px 'GothamBlack', sans-serif;
                letter-spacing: -0.36px;
                color: #000000;
                display: inline-block;
                margin-bottom: 0;
            }

            .menu-ano {
                margin-top: -2px;
                max-width: 382px;
                display: flex;
                align-items: center;
                gap: 31px;

                .menu {
                    width: 240px;
                    background-color: #eef4f4;
                    border-radius: 8px;
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;

                    button {
                        display: grid;
                        align-items: center;
                        padding-top: 2px;
                        height: 28px;
                        background: none;
                        border: none;
                        border-radius: 8px;
                        background-color: #eef4f4;
                        transition: 0.3s;
                        font: 12px 'GothamBold', sans-serif;
                        letter-spacing: -0.2px;
                        color: #000000;

                        &:hover {
                            background-color: #d5e4e1;
                        }
                    }

                    .selecionado {
                        background-color: #d5e4e1;
                    }
                }

                .ano {
                    font: 13px 'GothamBlack', sans-serif;
                    letter-spacing: -0.21px;
                    color: #000000;

                    @media (max-width: 1500px) and (min-width: 0px) {
                        font: 10px 'GothamBlack', sans-serif;
                    }
                }

            }

        }

        .filtro {
            .dropdown-icon {
                .dropdown-toggle {
                    font: 11px 'GothamBold', sans-serif;
                    letter-spacing: -0.18px;
                    color: #000000;

                    img {
                        margin-left: 10px;
                        margin-bottom: -2px;
                    }
                }

                .dropdown-menu {
                    width: 147px !important;
                    transform: translate(-95px, 32px) !important;
                    padding: 15px 15px;
                    display: grid;
                    gap: 10px;
                }
            }

        }

        .btn-criar-evento {

            //    .btnGreen {
            //         width: 132px;
            //         height: 28px;
            //         padding-left: 0;
            //         padding-right: 0;
            //         font: 12px 'GothamBold', sans-serif;
            //    }
            .show>.btn-primary.dropdown-toggle:focus {
                box-shadow: none !important;
                background: #04b7a7 !important;
            }

            .btnGreen {
                width: 132px;
                height: 28px;
                padding: 0px 10px;
                gap: 10px !important;
                // display: flex;
                align-items: center;
                border-radius: 8px;
                text-align: left;
                background: #04b7a7 !important;
                border: none;
                font: 12px GothamMedium, sans-serif;
                color: #ffffff;
                transition: 0.2s;

                &:hover {
                    background-color: #00CBB9 !important;
                    color: #fff;
                }

                &.more {
                    &::before {
                        content: '';
                        float: left;
                        // display: inline-block;
                        width: 15px;
                        height: 15px;
                        // margin: -1px 9px 0 -7px;
                        background-image: url("../../assets/images/add-circle.svg");
                        background-size: 15px 15px;
                        background-repeat: no-repeat;
                    }
                }

                &.load {
                    &::before {
                        content: "";
                        background: url('../../assets/images/carregamento-branco.gif');
                        background-repeat: no-repeat;
                        width: 13px;
                        height: 13px;
                        margin-left: -5px;
                        background-size: cover;
                        display: inline-flex;
                        justify-content: flex-end;
                        margin-right: 9px;
                        float: left;
                    }
                }
            }

            .criar-evento {
                .dropdown-menu {
                    transform: translate(0px, 36px) !important;
                    padding-bottom: 15px;
                }

                .notificacao-reuniao {
                    border: 2px solid #2BC78B;
                    border-radius: 10px;
                    display: grid;
                    width: 100%;
                    position: relative;
                    height: 66px;

                }

                .hr-nao-pode-igual {
                    p {
                        font: 10px 'GothamMedium', sans-serif !important;
                        color: #e95656 !important;
                        margin-bottom: 10px;
                    }
                }

                .formulario {
                    .titulo {
                        font: 14px 'GothamBlack', sans-serif;
                        color: #000000;
                        letter-spacing: -0.23px;
                    }

                    .data,
                    .titulo {
                        width: 100%;
                        border-left: none;
                        border-right: none;
                        border-top: none;
                        margin-bottom: 10px;
                        border-radius: 0;
                    }

                    .horas {
                        display: flex;
                        grid-template-columns: 1fr 1fr;
                        width: 100%;
                        gap: 15px;

                        select {
                            width: 50%;
                            padding-left: 0;
                            padding-right: 0;
                            border-left: none;
                            border-right: none;
                            border-top: none;
                            margin-bottom: 10px;
                            border-radius: 0;
                            background-position: right 10px center;
                        }

                        .error-hr {
                            border-bottom: 1px solid #e95656;
                        }
                    }

                    .tipo-evento {
                        margin-top: 6px;
                        border-bottom: 1px solid #EEEEEE;
                        padding-bottom: 3px;
                        margin-bottom: 10px;

                        .comercial {
                            width: 70px;
                            height: 25px;
                            border-radius: 4px;
                            font: 10px 'GothamBold', sans-serif;
                            letter-spacing: -0.16px;
                            color: #EC6D1D;
                            display: inline-block;
                            background-color: #FBEEE5;
                            margin-right: 10px;
                            margin-bottom: 10px;
                        }

                        .selecionado.comercial {
                            border: 1px solid #FBC6A5;
                        }

                        .devs {
                            width: 45px;
                            height: 25px;
                            border-radius: 4px;
                            font: 10px 'GothamBold', sans-serif;
                            letter-spacing: -0.16px;
                            color: #327EE4;
                            display: inline-block;
                            background-color: #F3F8FF;
                            margin-bottom: 10px;
                        }

                        .selecionado.devs {
                            border: 1px solid #327EE4;
                        }

                        .reuniao {
                            width: 60px;
                            height: 25px;
                            border-radius: 4px;
                            font: 10px 'GothamBold', sans-serif;
                            letter-spacing: -0.16px;
                            color: #2BC78B;
                            display: inline-block;
                            background-color: #E9FCF4;
                            margin-bottom: 10px;
                        }

                        .selecionado.reuniao {
                            border: 1px solid #2BC78B;
                        }
                    }

                    .buasca-colaborador {
                        width: 100%;
                        margin-bottom: 10px;

                        input {
                            width: 100%;
                            border-top: none;
                            border-left: none;
                            border-right: none;
                            border-radius: 0;
                        }

                    }

                    .resultado-busca-atrelados-col {
                        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.07);
                        border: solid 1px #d5dfdb;
                        border-radius: 6px;
                        background-color: #fff;
                        margin-top: 2px;
                        display: grid;
                        overflow-y: auto;
                        overflow-x: hidden;
                        max-height: 150px;
                        padding-bottom: 10px;
                        padding-top: 10px;
                        position: absolute;
                        width: 160px;
                        z-index: 99;

                        // display: none;
                        button {
                            border: none;
                            background: none;
                            width: 159px;
                            height: 42px;
                            font: 12px GothamMedium, sans-serif;
                            letter-spacing: -0.2px;
                            color: #3F544E;
                            display: flex;
                            gap: 10px;
                            align-items: center;

                            padding: 10px 24px;
                            background-color: #fff;
                            transition: all .2s ease-in-out;
                            cursor: pointer;
                            grid-template-columns: 20px 1fr;
                            margin-bottom: 0;

                            .img-col {
                                width: 20px;
                                height: 20px;

                                img {
                                    width: 20px;
                                    height: 20px;
                                    border-radius: 60%;
                                    object-fit: cover;
                                }
                            }

                            .colSemFoto {
                                display: flex;
                                align-items: center;

                                .semImgCol {
                                    width: 20px;
                                    height: 20px;
                                }
                            }

                            &:hover {
                                background-color: #EAF4F1;
                            }

                            span {
                                font: 12px GothamMedium, sans-serif;
                                font-weight: 500;
                                letter-spacing: -0.23px;
                                color: #000;
                                display: grid;
                                text-align: left;
                                width: 100%;
                                word-break: normal;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                                max-width: 62%;

                            }
                        }
                    }

                    .col-add {
                        display: grid;
                        grid-template-columns: 1fr 35px;
                        height: 34px;

                        .adicionados {
                            display: grid;
                            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                            height: 34px;
                            align-items: center;
                            gap: 10px;
                            width: 97%;
                            overflow: hidden;

                            .img-col {
                                width: 30px;
                                height: 30px;
                                display: inline-block;

                                img {
                                    width: 30px;
                                    height: 30px;
                                    border-radius: 60%;
                                }
                            }

                            .semImgCol {
                                display: inline-block;
                                width: 30px;
                                height: 30px;
                                display: flex;
                                align-items: center;
                            }
                        }

                        .add-button {
                            display: flex;
                            align-items: center;
                            align-items: center;

                            button {
                                height: 30px;
                                background: none;
                                margin-bottom: 0;
                            }
                        }
                    }

                    .salvar {
                        margin-bottom: 5px;
                        margin-top: 10px;
                        width: 100%;
                        height: 34px;
                        padding: 10px 20px;
                        display: flex;
                        align-items: center;
                        border-radius: 8px;
                        text-align: center;
                        background-color: #04b7a7;
                        border: none;
                        font: 12px GothamMedium, sans-serif;
                        color: #ffffff;
                        transition: 0.2s;
                        justify-content: center;

                        &:hover {
                            background-color: #00CBB9;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }

    .box-calendar-ano {
        width: 100%;
        margin-top: 20px;
        min-height: 350px;
        border: 1px solid #DDE5E6;

        .cabecalho {
            height: 50px;
            border-radius: 10px 10px 0 0;
            background-color: #EEF4F4;
            display: grid;
            grid-template-columns: 2fr 2fr 2fr 2fr 2fr 2fr 2fr;
            align-items: center;

            .dia {
                height: 50px;
                align-items: center;
                display: flex;
                justify-content: center;
                font: 18px GothamBlack, sans-serif;
                letter-spacing: -0.21px;
                color: #000000;
            }

            .bloco-2 {
                text-align: center;
                border-left: 1px solid #DDE5E6;

            }
        }

        .box-dia {
            display: grid;
            grid-template-columns: 2fr 2fr 2fr 2fr 2fr 2fr 2fr;
            align-items: center;
            margin-left: -1px;
            margin-bottom: -1px;
            // border-radius: 0 10px 10px 0;
            // overflow: hidden;
            border-radius: 10px !important;

            .dia {
                height: 66px;
                text-align: right;
                padding: 10px 11px 11px 11px;
                border-bottom: 1px solid #DDE5E6;
                border-left: 1px solid #DDE5E6;

                .dia-mes {
                    font: 14px GothamMedium, sans-serif;
                    color: #3F544E;
                }

                .dia-outro-mes {
                    font: 14px GothamMedium, sans-serif;
                    color: #8C939C;
                }
            }

            .notificacao-dopdown {
                .notificacao-reuniao {
                    border: 2px solid #017BFF;
                    color: #017BFF;
                    width: 100%;
                    font: 10px 'GothamMedium', sans-serif;
                    letter-spacing: -0.28px;
                    height: 28px;
                    border-radius: 8px;
                    margin-top: 5px;
                    padding: 5px;
                }

                .dropdown-menu {
                    width: 190px;
                    padding: 17px 12px 10px 12px;

                    .formulario {

                        max-height: 250px;
                        overflow: auto;

                        div:last-child {
                            border-bottom: none;
                        }
                    }

                    .azul {
                        h3 {
                            color: #017BFF;

                            span {
                                background-color: #017BFF;
                            }
                        }

                    }

                    .verde {
                        h3 {
                            color: #11BE7B;

                            span {
                                background-color: #11BE7B;
                            }
                        }
                    }

                    .laranjada {
                        h3 {
                            color: #FF7C01;

                            span {
                                background-color: #FF7C01;
                            }

                        }
                    }

                    .notificacao {
                        border-bottom: 1px solid hsl(187, 15%, 88%);
                        margin-bottom: 14px;
                        padding-bottom: 11px;

                        h3 {
                            font: 12px 'GothamBlack', sans-serif;
                            letter-spacing: -0.34px;
                            margin-bottom: 10px;
                            word-break: normal;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                            max-width: 90%;

                            span {
                                width: 9px;
                                height: 9px;
                                border-radius: 60%;
                                display: inline-block;
                                margin-bottom: -1px;
                                margin-right: 5px;
                            }
                        }

                        p {
                            font: 12px 'GothaBold', sans-serif;
                            letter-spacing: -0.34px;
                            color: #000;

                            span {
                                margin-right: 10px;
                                color: #666;
                            }

                            button {
                                display: inline-block;
                                margin-bottom: 0;
                                color: #000;
                            }

                        }

                    }
                }
            }

        }

    }

    .box-calendar-mes {
        margin-top: 20px;
        width: 100%;
        min-width: 30px;
        height: 386px;
        border-radius: 10px;
        border: 1px solid #DDE5E6;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        padding: 20px 20px;
        gap: 30px;

        button {
            display: grid;
            border-radius: 8px;
            background-color: #fff;
            border: solid 1px #d5dfdb;
            box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.07);
            font: 18px 'GothamBlack', sans-serif;
            letter-spacing: -0.28px;
            color: #000;
            padding: 20px;
            text-align: left;

            span {
                font: 12px 'GothamBlack', sans-serif;
                letter-spacing: -0.28px;
                color: #000;
                padding: 5px;
                border: 1px solid #017BFF;
                font: 12px 'GothamMedium', sans-serif;
                letter-spacing: -0.28px;
                color: #017BFF;
                border-radius: 8px;

            }
        }
    }

    .box-calendar-seman {
        margin-top: 20px;
        width: 100%;
        // height: 386px;
        height: calc(100% - 60px);
        overflow-y: hidden;
        overflow-x: hidden;
        border-radius: 10px;
        border: 1px solid #DDE5E6;

        @media (max-width: 1700px) and (min-width: 0) {
            height: calc(100% - 90px);

        }

        .header {
            height: 50px;
            border-radius: 10px 10px 0 0;
            background-color: #EEF4F4;
            display: grid;
            grid-template-columns: 60px 2fr 2fr 2fr 2fr 2fr 2fr 2fr;
            // grid-template-columns: 60px 125px 125px 125px 125px 125px 125px 125px;
            align-items: center;

            .bloco-um {
                align-items: center;
                text-align: center;
            }

            .bloco-dois {
                min-width: 100px;
                text-align: center;
                border-left: 1px solid #DDE5E6;
                height: 50px;
                padding-top: 11px;

                h1 {
                    font: 13px 'GothamBlack', sans-serif;
                    letter-spacing: -0.21px;
                    color: #000000;
                    margin-bottom: 0;
                }

                strong {
                    font: 11px 'GothamMedium', sans-serif;
                    letter-spacing: -0.21px;
                    color: #666666;

                }
            }
        }

        .corpo-novo-container {
            width: 100%;
            // height: 944px;
            height: 100%;
            overflow: hidden;

            >div {
                height: calc(100% - 49px) !important;
            }
        }

        .corpo-novo {
            display: grid;
            grid-template-columns: 61px 2fr 2fr 2fr 2fr 2fr 2fr 2fr;
            height: 335px;

            .bloco-hora {
                border-right: 1px solid #DDE5E6;

                .hora {
                    height: 66px;
                    font: 11px 'GothamMedium', sans-serif;
                    letter-spacing: -0, 18;
                    color: #666666;
                    text-align: center;
                    align-items: center;
                    display: grid;
                    border-bottom: 1px solid #DDE5E6;
                }

            }

            .bloco-dia-ultimo,
            .bloco-dia {
                border-right: 1px solid #DDE5E6;
                position: relative;

                min-width: 100px;

                .dropdown-menu {
                    transform: translate(126px, 0px) !important;
                }

                .linha {
                    position: absolute;
                    width: 100%;

                    div {
                        position: relative;
                        height: 66px;
                        border-bottom: 1px solid #DDE5E6;
                    }
                }

                .evento {
                    position: absolute;
                    padding: 0px 3px;
                    width: 100%;


                    .error-hr {
                        border-bottom: 1px solid #e95656 !important;
                    }

                    .hr-nao-pode-igual {
                        p {
                            font: 10px 'GothamMedium', sans-serif !important;
                            color: #e95656 !important;
                            margin-bottom: 10px;
                        }
                    }

                    .notificacao-comercial,
                    .notificacao-reuniao,
                    .notificacao-dev {
                        height: 28px;

                        span {
                            font: 9px 'GothamMedium', sans-serif;
                        }
                    }

                    .trinta,
                    .hora {
                        padding: 4px 0px;
                    }

                    .trinta {
                        height: 27px;
                        padding-top: 4px;

                        .dropdown-toggle {
                            height: 27px;
                            padding: 1px 15px;

                            h3 {
                                height: 13px;
                                overflow: hidden;
                                font: 11px 'GothamMedium', sans-serif;
                                margin-bottom: 0;
                            }
                        }
                    }

                    .hora {
                        height: 66px;
                        padding-top: 4px;

                        .dropdown-toggle {
                            height: 56px;

                            h3 {
                                max-height: 26px;
                                overflow: hidden;
                                margin-bottom: 5px;
                            }
                        }
                    }

                    .duas-horas {
                        height: 132px;
                        padding-top: 4px;

                        .dropdown-toggle {
                            height: 122px;
                        }
                    }

                    .treis-horas {
                        height: 198px;
                        padding-top: 4px;

                        .dropdown-toggle {
                            height: 188px;
                        }
                    }

                    .quatro-horas {
                        height: 264px;
                        padding-top: 4px;

                        .dropdown-toggle {
                            height: 254px;
                        }
                    }

                    .cinco-horas {
                        height: 330px;
                        padding-top: 4px;

                        .dropdown-toggle {
                            height: 320px;
                        }
                    }

                    .seis-horas {
                        height: 396px;
                        padding-top: 4px;

                        .dropdown-toggle {
                            height: 386px;
                        }
                    }

                    .sete-horas {
                        height: 462px;
                        padding-top: 4px;

                        .dropdown-toggle {
                            height: 452px;
                        }
                    }

                    .oito-horas {
                        height: 528px;
                        padding-top: 4px;

                        .dropdown-toggle {
                            height: 518px;
                        }
                    }

                    .nove-horas {
                        height: 594px;
                        padding-top: 4px;

                        .dropdown-toggle {
                            height: 584px;
                        }
                    }

                    .dez-horas {
                        height: 660px;
                        padding-top: 4px;

                        .dropdown-toggle {
                            height: 650px;
                        }
                    }

                    .onze-horas {
                        height: 726px;
                        padding-top: 4px;

                        .dropdown-toggle {
                            height: 716px;
                        }
                    }

                    .doze-horas {
                        height: 792px;
                        padding-top: 4px;

                        .dropdown-toggle {
                            height: 782px;
                        }
                    }

                    .treze-horas {
                        height: 858px;
                        padding-top: 4px;

                        .dropdown-toggle {
                            height: 848px;
                        }
                    }

                    .catorze-horas {
                        height: 924px;
                        padding-top: 4px;

                        .dropdown-toggle {
                            height: 914px;
                        }
                    }

                    .quinze-horas {
                        height: 990px;
                        padding-top: 4px;

                        .dropdown-toggle {
                            height: 980px;
                        }
                    }

                    .dezesseis-horas {
                        height: 1056px;
                        padding-top: 4px;

                        .dropdown-toggle {
                            height: 1046px;
                        }
                    }

                    .dezessete-horas {
                        height: 1122px;
                        padding-top: 4px;

                        .dropdown-toggle {
                            height: 1112px;
                        }
                    }

                    .dezoito-horas {
                        height: 1188px;
                        padding-top: 4px;

                        .dropdown-toggle {
                            height: 1178px;
                        }
                    }

                    .dezenove-horas {
                        height: 1254px;
                        padding-top: 4px;

                        .dropdown-toggle {
                            height: 1244px;
                        }
                    }

                    .vinte-horas {
                        height: 1320px;
                        padding-top: 4px;

                        .dropdown-toggle {
                            height: 1310px;
                        }
                    }

                    .vinteUm-horas {
                        height: 1386px;
                        padding-top: 4px;

                        .dropdown-toggle {
                            height: 1376px;
                        }
                    }

                    .vinteDois-horas {
                        height: 1452px;
                        padding-top: 4px;

                        .dropdown-toggle {
                            height: 1442px;
                        }
                    }

                    .vinteTreis-horas {
                        height: 1518px;
                        padding-top: 4px;

                        .dropdown-toggle {
                            height: 1508px;
                        }
                    }

                    .vinteQuatro-horas {
                        height: 1584px;
                        padding-top: 4px;

                        .dropdown-toggle {
                            height: 1574px;
                        }
                    }

                    .hora-meia {
                        height: 99px;
                        padding-top: 4px;

                        .dropdown-toggle {
                            height: 89px;
                        }
                    }

                    .duas-horas-meia {
                        height: 165px;
                        padding-top: 4px;

                        .dropdown-toggle {
                            height: 155px;
                        }
                    }

                    .treis-horas-meia {
                        height: 231px;
                        padding-top: 4px;

                        .dropdown-toggle {
                            height: 221px;
                        }
                    }

                    .quatro-horas-meia {
                        height: 297px;
                        padding-top: 4px;

                        .dropdown-toggle {
                            height: 297px;
                        }
                    }

                    .cinco-horas-meia {
                        height: 363px;
                        padding-top: 4px;

                        .dropdown-toggle {
                            height: 353px;
                        }
                    }

                    .seis-horas-meia {
                        height: 429px;
                        padding-top: 4px;

                        .dropdown-toggle {
                            height: 419px;
                        }
                    }

                    .sete-horas-meia {
                        height: 495px;
                        padding-top: 4px;

                        .dropdown-toggle {
                            height: 485px;
                        }
                    }

                    .oito-horas-meia {
                        height: 561px;
                        padding-top: 4px;

                        .dropdown-toggle {
                            height: 551px;
                        }
                    }

                    .nove-horas-meia {
                        height: 627px;
                        padding-top: 4px;

                        .dropdown-toggle {
                            height: 617px;
                        }
                    }

                    .dez-horas-meia {
                        height: 693px;
                        padding-top: 4px;

                        .dropdown-toggle {
                            height: 683px;
                        }
                    }

                    .onze-horas-meia {
                        height: 759px;
                        padding-top: 4px;

                        .dropdown-toggle {
                            height: 749px;
                        }
                    }

                    .doze-horas-meia {
                        height: 825px;
                        padding-top: 4px;

                        .dropdown-toggle {
                            height: 815px;
                        }
                    }

                    .treze-horas-meia {
                        height: 891px;
                        padding-top: 4px;

                        .dropdown-toggle {
                            height: 881px;
                        }
                    }

                    .catorze-horas-meia {
                        height: 957px;
                        padding-top: 4px;

                        .dropdown-toggle {
                            height: 947px;
                        }
                    }

                    .quinze-horas-meia {
                        height: 1023px;
                        padding-top: 4px;

                        .dropdown-toggle {
                            height: 1013px;
                        }
                    }

                    .dezesseis-horas-meia {
                        height: 1089px;
                        padding-top: 4px;

                        .dropdown-toggle {
                            height: 1079px;
                        }
                    }

                    .dezessete-horas-meia {
                        height: 1155px;
                        padding-top: 4px;

                        .dropdown-toggle {
                            height: 1145px;
                        }
                    }

                    .dezoito-horas-meia {
                        height: 1221px;
                        padding-top: 4px;

                        .dropdown-toggle {
                            height: 1211px;
                        }
                    }

                    .dezenove-horas-meia {
                        height: 1287px;
                        padding-top: 4px;

                        .dropdown-toggle {
                            height: 1277px;
                        }
                    }

                    .vinte-horas-meia {
                        height: 1353px;
                        padding-top: 4px;

                        .dropdown-toggle {
                            height: 1343px;
                        }
                    }

                    .vinteUm-horas-meia {
                        height: 1419px;
                        padding-top: 4px;

                        .dropdown-toggle {
                            height: 1409px;
                        }
                    }

                    .vinteDois-horas-meia {
                        height: 1485px;
                        padding-top: 4px;

                        .dropdown-toggle {
                            height: 1475px;
                        }
                    }

                    .vinteTreis-horas-meia {
                        height: 1551px;
                        padding-top: 4px;

                        .dropdown-toggle {
                            height: 1541px;
                        }
                    }

                    .vinteQuatro-horas-meia {
                        height: 1617px;
                        padding-top: 4px;

                        .dropdown-toggle {
                            height: 1607px;
                        }
                    }
                }
            }

            .position-0,
            .position-1,
            .position-2,
            .position-3,
            .position-4,
            .position-5,
            .position-6,
            .position-7,
            .position-8,
            .position-9,
            .position-10,
            .position-11,
            .position-12,
            .position-13,
            .position-14,
            .position-14,
            .position-15,
            .position-16,
            .position-17,
            .position-18,
            .position-19,
            .position-20,
            .position-21,
            .position-22,
            .position-23 {
                .dropdown-menu {
                    transform: none !important;
                    margin-left: 106% !important;
                }
            }



            .bloco-dia-penutimo {

                min-width: 100px;

                // .dropdown-menu {
                //     transform: translate(-221px, 0px) !important;
                // }

                .position-0,
                .position-1,
                .position-2,
                .position-3,
                .position-4,
                .position-5,
                .position-6,
                .position-7,
                .position-8,
                .position-9,
                .position-10,
                .position-11,
                .position-12,
                .position-13,
                .position-14,
                .position-14,
                .position-15,
                .position-16,
                .position-17,
                .position-18,
                .position-19,
                .position-20,
                .position-21,
                .position-22,
                .position-23 {
                    .dropdown-menu {
                        margin-left: -220px !important;
                    }
                }

            }

            .bloco-dia-ultimo {
                border: none;

                min-width: 90px;

                .position-0,
                .position-1,
                .position-2,
                .position-3,
                .position-4,
                .position-5,
                .position-6,
                .position-7,
                .position-8,
                .position-9,
                .position-10,
                .position-11,
                .position-12,
                .position-13,
                .position-14,
                .position-14,
                .position-15,
                .position-16,
                .position-17,
                .position-18,
                .position-19,
                .position-20,
                .position-21,
                .position-22,
                .position-23 {
                    .dropdown-menu {
                        margin-left: -220px !important;
                    }
                }
            }

            .position-0 {
                .dropdown-menu {
                    margin-top: 4px !important;
                }
            }

            .position-1 {
                .dropdown-menu {
                    margin-top: -30px !important;
                }
            }

            .position-2,
            .position-3,
            .position-4,
            .position-5,
            .position-6,
            .position-7,
            .position-8,
            .position-9,
            .position-10,
            .position-11,
            .position-12,
            .position-13,
            .position-14,
            .position-14,
            .position-15,
            .position-16,
            .position-17,
            .position-18,
            .position-19,
            .position-20 {
                .dropdown-menu {
                    margin-top: -106px !important;
                }
            }

            .position-21 {
                .dropdown-menu {
                    margin-top: -180px !important;
                }
            }

            .position-22 {
                .dropdown-menu {
                    margin-top: -240px !important;
                }
            }

            .position-23 {
                .dropdown-menu {
                    margin-top: -290px !important;
                }
            }


        }

        .criar-evento {
            .dropdown-toggle {
                padding: 6px 15px;
                display: grid;
                align-items: flex-start;

                div {
                    height: 100%;
                }

                h3 {
                    font: 13px 'GothamBlack', sans-serif;
                    letter-spacing: -0.21px;
                    color: #000;
                    text-align: left;
                    line-height: 15px;
                    margin-bottom: 9px;
                    // word-break: normal;
                    text-overflow: ellipsis;
                    white-space: initial;
                    display: flex;
                    width: 100%;
                    max-height: 46px;
                    padding-bottom: 1px;
                    overflow: hidden;
                    word-break: break-word;
                }

                span {
                    font: 11px 'GothamMedium', sans-serif;
                    letter-spacing: -0.18px;
                    color: #666666;
                    display: grid;
                    text-align: left;

                }
            }

            .notificacao-reuniao {
                border: 2px solid #2BC78B;
                background: #f6fffc !important;
                border-radius: 10px;
                width: 100%;
                position: absolute;

                // height: 66px;
                &:focus {
                    background: #f6fffc !important;
                    border: 2px solid #2BC78B;
                }
            }

            .notificacao-dev {
                border: 2px solid #327EE4;
                background: #f3f8ff !important;
                border-radius: 10px;
                width: 100%;
                position: absolute;

                // height: 66px;
                &:focus {
                    background: #f3f8ff !important;
                    border: 2px solid #327EE4;
                }

            }

            .notificacao-comercial {
                border: 2px solid #EC6D1D;
                background: #fef6f0 !important;
                border-radius: 10px;
                width: 100%;
                position: absolute;

                // height: 66px;
                &:focus {
                    background: #fef6f0 !important;
                    border: 2px solid #EC6D1D;
                }

            }

            .formulario {

                .data,
                .titulo {
                    width: 100%;
                    border-left: none;
                    border-right: none;
                    border-top: none;
                    margin-bottom: 10px;
                    border-radius: 0;
                }

                .horas {
                    display: flex;
                    grid-template-columns: 1fr 1fr;
                    width: 100%;
                    gap: 15px;

                    select {
                        width: 50%;
                        padding-left: 0;
                        padding-right: 0;
                        border-left: none;
                        border-right: none;
                        border-top: none;
                        margin-bottom: 10px;
                        border-radius: 0;
                        background-position: right 10px center;
                    }
                }

                .tipo-evento {
                    margin-top: 6px;
                    border-bottom: 1px solid #EEEEEE;
                    padding-bottom: 3px;
                    margin-bottom: 10px;

                    .comercial {
                        width: 70px;
                        height: 25px;
                        border-radius: 4px;
                        font: 10px 'GothamBold', sans-serif;
                        letter-spacing: -0.16px;
                        color: #EC6D1D;
                        display: inline-block;
                        background-color: #FBEEE5;
                        margin-right: 10px;
                        margin-bottom: 10px;
                    }

                    .selecionado.comercial {
                        border: 1px solid #FBC6A5;
                    }

                    .devs {
                        width: 45px;
                        height: 25px;
                        border-radius: 4px;
                        font: 10px 'GothamBold', sans-serif;
                        letter-spacing: -0.16px;
                        color: #327EE4;
                        display: inline-block;
                        background-color: #F3F8FF;
                        margin-bottom: 10px;
                    }

                    .selecionado.devs {
                        border: 1px solid #327EE4;
                    }

                    .reuniao {
                        width: 60px;
                        height: 25px;
                        border-radius: 4px;
                        font: 10px 'GothamBold', sans-serif;
                        letter-spacing: -0.16px;
                        color: #2BC78B;
                        display: inline-block;
                        background-color: #E9FCF4;
                        margin-bottom: 10px;
                    }

                    .selecionado.reuniao {
                        border: 1px solid #2BC78B;
                    }
                }

                .buasca-colaborador {
                    width: 100%;
                    margin-bottom: 10px;

                    input {
                        width: 100%;
                        border-top: none;
                        border-left: none;
                        border-right: none;
                        border-radius: 0;
                    }

                }

                .resultado-busca-atrelados-col {
                    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.07);
                    border: solid 1px #d5dfdb;
                    border-radius: 6px;
                    background-color: #fff;
                    margin-top: 2px;
                    display: grid;
                    overflow-y: auto;
                    overflow-x: hidden;
                    max-height: 150px;
                    padding-bottom: 10px;
                    padding-top: 10px;
                    position: absolute;
                    width: 160px;
                    z-index: 99;

                    // display: none;
                    button {
                        border: none;
                        background: none;
                        width: 100%;
                        height: 42px;
                        font: 12px GothamMedium, sans-serif;
                        letter-spacing: -0.2px;
                        color: #3F544E;
                        display: flex;
                        gap: 10px;
                        align-items: flex-start;
                        padding: 10px 24px;
                        background-color: #fff;
                        transition: all .2s ease-in-out;
                        cursor: pointer;
                        grid-template-columns: 20px 1fr;

                        .img-col {
                            width: 20px;
                            height: 20px;

                            img {
                                width: 20px;
                                height: 20px;
                                border-radius: 60%;
                                object-fit: cover;
                            }
                        }

                        .colSemFoto {
                            display: flex;
                            align-items: center;

                            .semImgCol {
                                width: 20px;
                                height: 20px;
                            }
                        }

                        &:hover {
                            background-color: #EAF4F1;
                        }

                        span {
                            font: 12px GothamMedium, sans-serif;
                            font-weight: 500;
                            letter-spacing: -0.23px;
                            color: #000;
                            display: grid;
                            text-align: left;
                            width: 100%;

                        }
                    }
                }

                .col-add {
                    display: grid;
                    grid-template-columns: 1fr 35px;
                    height: 34px;

                    .adicionados {
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                        height: 34px;
                        align-items: center;
                        gap: 10px;
                        width: 97%;
                        overflow: hidden;

                        .img-col {
                            width: 30px;
                            height: 30px;
                            display: inline-block;

                            img {
                                width: 30px;
                                height: 30px;
                                border-radius: 60%;
                            }
                        }

                        .semImgCol {
                            display: inline-block;
                            width: 30px;
                            height: 30px;
                            display: flex;
                            align-items: center;
                        }
                    }

                    .add-button {
                        display: flex;
                        align-items: center;
                        align-items: center;

                        button {
                            height: 30px;
                            background: none;
                            margin-bottom: 0;
                        }
                    }
                }

                .salvar {
                    margin-bottom: 5px;
                    margin-top: 10px;
                    width: 100%;
                    height: 34px;
                    padding: 10px 20px;
                    display: flex;
                    align-items: center;
                    border-radius: 8px;
                    text-align: center;
                    background-color: #04b7a7;
                    border: none;
                    font: 12px GothamMedium, sans-serif;
                    color: #ffffff;
                    transition: 0.2s;
                    justify-content: center;

                    &:hover {
                        background-color: #00CBB9;
                        color: #fff;
                    }
                }
            }
        }
    }
}