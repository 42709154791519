///////////////////////////////////////////////////////
/////////////// PAGINA DE DESLIGAMENTO ///////////////



.page-desligamento {
    padding: 22px 34px 67px 44px;
    margin-top: 15px;
    margin-bottom: 50px;
    border-radius: 8px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.07);
    border: solid 1px #d5dfdb;
    background-color: #ffffff;
    .titulo {
        display: grid;
        grid-template:"titulo button"
       / 2fr 1fr;
       margin-bottom: 20px;
        h2 {
            grid-area: titulo;
            font: 22px 'GothamBlack',sans-serif;
            letter-spacing: -0.36px;
            color: #000000;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
        .btn-back {
            grid-area: button;
            a {
                width: 225px;
                height: 42px;
                padding: 8px 12px;
                border-radius: 8px;
                background-color: #e6ecec;
                font: 12px GothamBold, sans-serif;
                color: #3f544e;
                border: none;
                float: right;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
    .listas-desligamento {
        display: grid;
        grid-template-columns: 327px 327px;
        gap: 63px;
        @media (max-width: 830px) and (min-width: 0px) {
            grid-template-columns: 1fr 1fr;
            gap: 35px;
        }
        .pentente {
            h3 {
                color: #ec6d1d;
                i {
                    background: #ec6d1d;
                }
            }
            .tooltipData {
                .tooltipInfor {
                    i {
                        background-color: #EC6D1D;
                    }
                }
            }
            .nenhum-colaborador {
                padding: 13px 19px 13px;
                min-height: 79px;
                border-radius: 8px;
                box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.07);
                border: solid 1px #d5dfdb;
                background-color: #ffffff;
                display: grid;
                align-items: center;
                 p {
                    font: 13px 'GothamBold',sans-serif;
                    color: #999999;
                    width: 70%;
                    text-align: center;
                    margin: 0 auto;
                }
            }
        }
        .recentes {
            h3 {
                color: #e95656;
                i {
                    background: #e95656;
                }
            }
            .tooltipData {
                .tooltipInfor {
                    i {
                        background-color: #e95656;
                    }
                }
            }
            .nenhum-colaborador {
                padding: 13px 19px 13px;
                min-height: 79px;
                border-radius: 8px;
                box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.07);
                border: solid 1px #d5dfdb;
                background-color: #ffffff;
                display: grid;
                align-items: center;
                 p {
                    font: 13px 'GothamBold',sans-serif;
                    color: #999999;
                    width: 70%;
                    text-align: center;
                    margin: 0 auto;
                }
            }
        }
        .pentente, .recentes {
            h3 {
                height: 35px;
                font: 14px "GothamBold",sans-serif;
                display: -webkit-flex;
                display: flex;
                -webkit-justify-content: flex-start;
                justify-content: flex-start;
                -webkit-align-items: center;
                align-items: center;
                i {
                    width: 27px;
                    height: 15px;
                    margin: -3px 13px 0 0;
                    border-radius: 18px;
                    float: left;
                }
            }
            .carregando {
                text-align: center;
                img {
                    width: 34px;
                }
            }
            .nenhum-col-etapa {
                width: 327px;
                height: 79px;
                border-radius: 8px;
                box-shadow: 0 2px 3px 0 #ccc;
                border: 1px solid #d5dfdb;
                display: flex;
                justify-content: center;
                font: 12px "GothamMedium",sans-serif;
                line-height: 17px;
                color: #ccc;
                padding: 0 72px;
                text-align: center;
            }
            ul {
                padding-top: 5px;
                display: grid;
                grid-gap: 25px;
                gap: 25px;
                li {
                    padding: 13px 19px;
                    max-width: 327px;
                    height: 79px;
                    border-radius: 8px;
                    box-shadow: 0 2px 3px 0 #cccccc3b;
                    border: 1px solid #d5dfdb;
                    background-color: #fff;
                    display: grid;
                   grid-template: "foto nome verMais"
                   "foto cargo verMais"
                   " tooltipData tooltipData tooltipData"/53px 2fr .2fr;

                   .foto {
                        grid-area: foto;
                        .com-foto {
                            width: 35px;
                            height: 35px;
                            border-radius: 50%;
                            object-fit: cover;
                        }
                        .semImgCol {
                            width: 35px;
                            height: 35px;
                        }
                    }

                    .nome {
                        font: 14px "GothamBold",sans-serif;
                        color: #000;
                        font-weight: 700;
                        margin-bottom: 4px;
                        grid-area: nome;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .cargo {
                        font: 12px "GothamMedium",sans-serif;
                        color: #999;
                        grid-area: cargo;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .verMais {
                        grid-area: verMais;
                        margin-top: -6px;
                        margin-right: -6px;
                        .dropdown-menu {
                            width: 142px;
                            transform: translate(-134px, 24px) !important;
                        }
                    }
                    .tooltipData {
                        padding-top: 7px;
                        grid-area: tooltipData;
                        display: grid;
                        grid-template: "tooltipInfor date"/1fr 3fr;
                        .tooltipInfor {
                            grid-area: tooltipInfor;
                            display: -webkit-flex;
                            display: flex;
                            -webkit-align-items: center;
                            align-items: center;
                            i {
                                display: inline-block;
                                width: 27px;
                                height: 6px;
                                border-radius: 18px;
                                margin-left: 3px;
                                margin-bottom: -4px;
                                .tooltiptext {
                                    font-size: 11px;
                                    border-radius: 8px;
                                    padding: 12px 11px;
                                    box-shadow:rgba(0, 0, 0, 0.07);
                                    border: 1px solid #d5dfdb;
                                    background-color: #fff;
                                    color: #333;
                                    position: absolute;
                                    z-index: 1;
                                    margin-left: -72px;
                                    margin-top: 15px;
                                    visibility: hidden;
                                    font-stretch: normal;
                                    font-style: normal;
                                }
                                &:hover {
                                    .tooltiptext {
                                        visibility: visible;
                                    }
                                    &::after {
                                            content: "";
                                            position: absolute;
                                            margin-left: 7px;
                                            margin-top:4px;
                                            border-width: 7px;
                                            border-style: solid;
                                            border-color: transparent transparent #fff transparent;

                                        z-index: 2;
                                    }
                                    &::before {
                                            content: "";
                                            position: absolute;
                                            margin-left: 6px;
                                            margin-top: 1px;
                                            border-width: 8px;
                                            border-style: solid;
                                            border-color: transparent transparent #d5dfdb transparent;
                                    }
                                }
                            }
                        }
                        .date {
                            grid-area: date;
                            display: -webkit-flex;
                            display: flex;
                            -webkit-justify-content: flex-end;
                            justify-content: flex-end;
                            -webkit-align-items: center;
                            align-items: center;
                            font: 10px "GothamMedium",sans-serif;
                            color: #8c939c;
                        }
                    }

                    @media (max-width: 700px) and (min-width: 0px) {
                        grid-template: "nome verMais"
                        "cargo verMais"
                        "tooltipData tooltipData"/2fr .2fr;
                        .foto {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    .loading {
        display: grid;
        grid-template:"loading loading loading"
       / 1fr 1fr 1fr;

       .carregando {
        grid-area: loading;
        text-align: center;
        padding-top: 10px;
        img {
            width: 34px;
        }
    }

    }
}

.page-desligamento.mobile {
    padding: 15px 30px 0px 30px;
    display: none;
    border-radius: 0;
    border: none;
    box-shadow: none;
    margin-top: 0px;
    .titulo {
        display: grid;
        grid-template: "titulo" "button" /  1fr;
        margin-bottom: 20px;
        gap: 20px;
        h2 {
            font: 20px 'GothamBlack',sans-serif;
            letter-spacing: -0.33px;
        }
        .btn-back {
            a {
                width: 100%;
            }
        }
    }


    .select-shutdowProcess {
        .tituloMostrando {
            font: 12px 'GothamMedium',sans-serif;
            color: #999999;
            margin-bottom: 12px;
        }
        .cont-select {
            .select- {
                p {
                    font: 12px 'GothamBold',sans-serif;
                    letter-spacing: -0.24px;
                    // display: flex;
                    // align-items: center;
                    // gap: 10px;
                    span {
                        width: 22px;
                        height: 11px;
                        border-radius: 18px;
                        display: inline-block;
                        margin-bottom: -1px;
                        margin-right: 11px;
                    }
                }
                .pendente {
                    color: #EC6D1D;
                    span {
                        background: #EC6D1D;
                    }
                }
                .desligamento-recente {
                    color: #E95656;
                    span {
                        background: #E95656;
                    }
                }

            }
        }
    }

    .listas-desligamento {
        grid-template-columns: 1fr !important;
        padding-top: 17px;

        .pentente, .recentes {
            ul {
                gap: 20px;
                li {
                    max-width: 100%;
                    grid-template:
                    "foto nome verMais"
                    "foto cargo verMais"
                    " tooltipData tooltipData tooltipData"/53px 2fr 25px;
                    .foto {
                        display: block;
                    }
                }
                .nenhum-colaborador {
                    height: 183px;
                }
            }
        }
    }
}

@media (max-width: 600px) and (min-width: 0px) {
    .page-desligamento.desktop {
        display: none;
    }

    .page-desligamento.mobile {
        display: block;
    }
    
}


.page-todos-desligamento {
    display: grid;
    padding: 22px 34px 67px 44px;
    margin-top: 15px;
    margin-bottom: 50px;
    border-radius: 8px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.07);
    border: solid 1px #d5dfdb;
    background-color: #ffffff;
    .titulo {
        display: grid;
        grid-template:"titulo button"
       / 2fr 1fr;
       margin-bottom: 20px;
        h2 {
            grid-area: titulo;
            font: 22px 'GothamBlack',sans-serif;
            letter-spacing: -0.36px;
            color: #000000;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
        .btn-back {
            grid-area: button;
            a {
                width: 225px;
                height: 42px;
                padding: 8px 12px;
                border-radius: 8px;
                background-color: #e6ecec;
                font: 12px GothamBold, sans-serif;
                color: #3f544e;
                border: none;
                float: right;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
    .nenhum-colaborador {
        padding: 13px 19px 13px;
        width: 273px;
        min-height: 79px;
        border-radius: 8px;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.07);
        border: solid 1px #d5dfdb;
        background-color: #ffffff;
        display: grid;
        align-items: center;
        p {
            font: 13px 'GothamBold',sans-serif;
            color: #999999;
        }
    }
    .busca-desligamento {
        input {
            width: 440px;
            height: 42px;
        }
    }
    @media (max-width: 1600px) and (min-width: 1200px) {
        padding: 22px 30px 20px 30px;
    }
    .tabela {
        display: grid;
        grid-gap: 15px;
        gap: 15px;
        margin-top: 20px;
        .tabelaTR {
            background: #fff;
            border-radius: 8px;
            box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.07);
            border: 1px solid #d5dfdb;
            display: grid;
            grid-template: "inten-1 inten-2 inten-3 inten-4"/4fr 4fr 3.5fr .2fr;
            td {
                padding: 0 23px;
                display: flex;
                -webkit-align-items: center;
                align-items: center;
                > a {
                    width: 100%;
                    height: 100%;
                    padding: 10px 0;
                    font: 12px "GothamMedium",sans-serif;
                    color: #3f544e;
                    display: -webkit-flex;
                    display: flex;
                    -webkit-align-items: center;
                    align-items: center;
                }
                .colaborador {
                    display: grid;
                    grid-template:
                    "img nome"
                    "img p"/.13fr 1fr;
                    .com-foto {
                        grid-area: img;
                        width: 35px;
                        height: 35px;
                        border-radius: 50%;
                        margin-right: 18px;
                        object-fit: cover;
                    }
                    .semImgCol {
                        grid-area: img;
                        width: 35px;
                        height: 35px;
                        margin-right: 18px;
                    }
                    strong {
                        font: 14px "GothamBold",sans-serif;
                        color: #000;
                        grid-area: nome;
                    }
                    p {
                        font: 12px "GothamMedium",sans-serif;
                        color: #999;
                        margin-bottom: 0;
                        grid-area: p;
                    }
                }
            }
            .inten-1 {
                grid-area: inten-1;

            }
            .inten-2 {
                grid-area: inten-2;
            }
            .inten-3 {
                grid-area: inten-3;
            }
            .inten-4 {
                grid-area: inten-4;

                    .dropdown-toggle {
                        img {
                            margin-top: 0;
                        }
                    }
            }

            @media (max-width: 1600px) and (min-width: 1200px) {
                grid-template: "inten-1 inten-2 inten-3 inten-4"/4fr 2.5fr 160px .2fr;
            }

            @media (max-width: 1200px) and (min-width: 900px) {
                grid-template: "inten-1 inten-2 inten-3 inten-4"/4fr 2.5fr 180px .2fr;
                td {
                    padding: 0 10px;
                }
            }

            @media (max-width: 900px) and (min-width: 600px) {
                .inten-2 {
                   display: none;
                }
                grid-template: "inten-1 inten-3 inten-4"/4fr 180px .2fr;
                td {
                    padding: 0 10px;
                }
            }

            @media (max-width: 600px) and (min-width: 0px) {
                .inten-3,
                .inten-2 {
                   display: none;
                }
                grid-template: "inten-1 inten-4"/4fr .2fr;

            }



        }
    }
    .carregando {
        text-align: center;
        padding-top: 10px;
        img {
            width: 34px;
        }
    }
    .paginacaoG {
        margin-top: 35px;
        position: relative;

        @media (max-width: 600px) and (min-width: 0px) {
            margin-top: 25px;
            margin-left: 20px;

        }
    }
    .busca-button {
        display: none;
    }

    @media (max-width: 600px) and (min-width: 0px) {
        border: none;
        box-shadow: none;
        padding: 15px 0;
        margin-top: 0;
        .titulo {   
            display: grid;
            grid-template:
            "titulo"
            "button" / 1fr;
            margin-bottom: 17px;
            gap: 20px;
            padding-left: 30px;
            padding-right: 30px;
            h2 {
                grid-area: titulo;
                font: 20px 'GothamBlack',sans-serif;
                letter-spacing: -0.33px;
            }
            .btn-back {
                a {
                    width: 100%;
                }
            }
        }
        
        .busca-button {
            display: block;
            padding-left: 30px;
            padding-right: 30px;
            form {
                width: 100%;
                input {
                    width: 100%;
                    height: 42px;
                }
            }
            a {
                display: grid;
                align-items: center;
                justify-content: center;
                position: fixed;
                bottom: 30px;
                right: 30px;
                width: 40px;
                height: 40px;
                background: #04B7A7;
                border: none;
                border-radius: 60%;
                z-index: 99;
            }
        }
        .tabela {
            gap: 0;
            .tabelaTR {
                box-shadow: none;
                border-radius: 0;
                border-bottom: none;
                min-height: 65px;
                padding-left: 30px;
                padding-right: 30px;
                .inten-1 {
                    margin-right: 10px;
                }
                td{
                    padding: 0;
                }
            }

        }

    }
}
///////////////////////////////////////////
////////////////// Nova Demissao //////////
///////////////////////////////////////////

.progressBarDesligamento {
    margin-bottom: 25px;
    margin-top: 20px;
    display: grid;
    grid-template: "contabilidade revisao-documentos finalizar" /1fr 1fr 1fr;
    align-items: flex-end;
    .contabilidade {
        grid-area: contabilidade;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        .nameSession {
            text-align: left;
        }
    }
    .revisao-documentos {
        grid-area: revisao-documentos;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .nameSession {
            text-align: center;
        }
    }
    .finalizar {
        grid-area: finalizar;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        .nameSession {
            text-align: right;
        }
    }
    .linha-left {
        margin-left: 10px;
    }
    .linha-right {
        margin-right: 10px;
    }
    .contabilidade,
    .revisao-documentos,
    .finalizar {
        .nameSession {
            font: 15px GothamBold,sans-serif;
            color: #8c939c;
            width: 100%;
            margin-top: 4px;
            margin-bottom: 16px;
            p {
                margin-bottom: 0;
            }
        }
        .number {
            width: 43px;
            height: 43px;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1;
            p {
                width: 33px;
                height: 33px;
                background-color: #d5dfdb;
                border-radius: 60%;
                font: 20px GothamBlack,sans-serif;
                color: #fff;
                margin-bottom: 0;
                display: -webkit-flex;
                display: flex;
                -webkit-justify-content: center;
                justify-content: center;
                -webkit-align-items: center;
                align-items: center;
            }
        }
        .Linha {
            height: 4px;
            width: 100%;
            background-color: #d5dfdb;
            z-index: 0;
            margin-top: -23px;
        }
    }


    .session {
        .nameSession {
            font: 18px GothamBlack,sans-serif;
            color: #000;
            margin-bottom: 14px;
            margin-top: 3px;
        }
        .number {
            background-color: #04b7a7;
            border-radius: 60%;
            justify-content: center;
            p {
                width: 39px;
                height: 39px;
                border: 3px solid #fff;
                background-color: #04b7a7;
                color: #fff;
            }
        }
        .Linha {
            height: 4px;
            background-color: #04b7a7;
        }
    }
    .previousSession {
        .nameSession {
            font: 18px GothamBlack, sans-serif;
            margin-bottom: 14px;
            margin-top: 3px;
        }
        .number {
            div {
                width: 43px;
                height: 43px;
                display: flex;
                align-items: center;
                justify-content: center;
                p {
                    background-color: #04b7a7;
                }

            }
        }
        .Linha {
            background-color: #04b7a7;
        }
    }
    .nameSession-mb {
        display: none;
        grid-area: nameSessionMB;
        h1 {
            font: 18px GothamBlack,sans-serif;
            color: #000;
            margin-bottom: 14px;
            margin-top: 3px;

        }

    }

    @media (max-width: 600px) and (min-width: 0px) {
        grid-template: "nameSessionMB nameSessionMB nameSessionMB"
        "contabilidade revisao-documentos finalizar" /1fr 1fr 1fr;
        padding: 0 30px;
        margin-top: 10px;
        .nameSession {
            display: none;
        }
        .nameSession-mb{
            display: block;
        }
    }
}
.desligamento-etapa-um {
    padding: 36px 44px;
    border-radius: 8px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.07);
    border: 1px solid #d5dfdb;
    background-color: #fff;
    margin-bottom: 25px;
    display: grid;
    grid-template: "titulo titulo" "form infor"/460px 385px;
    gap: 73px;
    row-gap: 43px;
    position: relative;
    @media (max-width: 1600px) and (min-width:1200px) {
        grid-template: "titulo titulo" "form infor"/360px 385px;
        gap: 40px;
    }
    @media (max-width: 1200px) and (min-width: 800px) {
        padding: 36px 30px;
        grid-template: "titulo titulo" "form infor"/1fr 1fr;
        gap-row: 42px;
        gap: 40px;
    }
    @media (max-width: 800px) and (min-width: 0px) {
        padding: 36px 30px;
        grid-template: "titulo titulo" "infor infor" "form form"/1fr 1fr;
        gap: 40px;
    }

    @media (max-width: 600px) and (min-width: 0px) {
        box-shadow: none;
        border: none;
        padding: 10px 30px;
        row-gap: 30px;
    }
    .titulo {
        grid-area: titulo;
        font: 22px GothamBlack,sans-serif;
        color: #000;
        margin-bottom: 0;
    }
    .form-desligamentp {
        grid-area: form;
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 16px;

        .box {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 260px;
            grid-row-gap: 16px;
            column-gap: 30px;
            textarea {
                width: 100%;
                height: 110px;
            }
            .cont-select{
                .dropDown {
                    width: 260px;
                }
            }
            .inputs {
                position: relative;

                .textError {
                    font: 9px GothamMedium, sans-serif;
                    margin-top: 2px;
                    margin-bottom: -13px;
                }
            }
            .textCampo {
                font: 12px GothamMedium, sans-serif;
                color: #999999;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                text-align: right;
                height: 38px;
            }
            input {
                width: 100%;
            }
            @media (max-width: 1600px) and (min-width: 0px) {
                gap: 15px;
            }
            @media (max-width: 800px) and (min-width: 600px) {
                grid-template-columns: 1fr 2fr;
            }
            @media (max-width: 600px) and (min-width: 0px) {
                grid-template-columns: 1fr;
                .textCampo {
                    justify-content: flex-start;
                    height: 10px;
                }
            }

        }
        .infor-doc-dropzone {
            margin-top: 5px;
        }
        .imgDocLabel {
            padding-left: 11px;
        }

        .ArquivosEnviados {
            h5 {
                font: 14px GothamBlack, sans-serif;
                color: #000;
                margin-bottom: 30px;
            }
            .arquivos {
                display: grid;
                grid-template:"nameArquivo dataArquivo btnExclui"
                /1.3fr 153px 90px;
                grid-gap: 15px;
                margin-bottom: 30px;
                .nameArquivo {
                    grid-area: nameArquivo;

                        word-break: normal;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        max-width: 99%;
                        display: flex;
                        align-items: center;
                    a {
                        display: block;
                        font: 10px GothamBlack, sans-serif;
                        color: #3f544e;
                        word-break: normal;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        max-width: 99%;
                    }
                }
                .dataArquivo {
                    grid-area: dataArquivo;
                    font: 12px GothamMedium, sans-serif;
                    color: #999999;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                }
                .btnExclui {
                    grid-area: btnExclui;
                    font: 12px GothamBold, sans-serif;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    button {
                        background: none;
                        border: none;
                        color: #e95656;
                    }
                }

                @media (max-width: 500px) and (min-width: 0px) {
                    justify-content: flex-end;
                    text-align: right;
                    grid-template:"nameArquivo nameArquivo"
                    "dataArquivo btnExclui"
                    / 1fr 83px;
                    gap: 10px;
                    .nameArquivo {
                        justify-content: flex-end;
                        text-align: right;
                    }
                }
            }
            .nenhum-arq {
                p {
                    font: 14px GothamMedium, sans-serif;
                    color: #fe8c60;
                    margin-bottom: 30px;
                }
            }
        }
        .chekbox {
            display: flex;
            align-items: center;
            height: 38px;
            .chackboxLabel {
                cursor: pointer;
                width: 18px;
                height: 18px;
                border-radius: 3px;
                border: solid 1px #c1d0ca;
                margin-bottom: 0;
                margin-right: 10px;
            }
            .foreignTexto {
                margin-bottom: 0;
                margin-right: 25px;
                cursor: pointer;
            }
            input {
                display: none;
            }
            input[type=radio]:checked + .chackboxLabel {
                background-image: url("../../../../assets/images/check.svg");
                background-repeat: no-repeat;
                background-position: 4px 5px;
            }
        }
    }
    .infor-colaborador {
        grid-area: infor;
        .box {
            width: 100%;
            border-radius: 8px;
            box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.07);
            border: 1px solid #d5dfdb;
            padding: 28px 30px 40px ;
            .fotoColl {
                width: 80px;
                height: 80px;
                border-radius: 50%;
                margin-right: 18px;
                object-fit: cover;
                margin-bottom: 25px;
            }
            .semImgCol {
                width: 80px;
                height: 80px;
                margin-bottom: 25px;
            }

            .col-nome {
                font: 22px GothamBlack,sans-serif;
                color: #000;
                margin-bottom: 5px;
            }
            .col-cargo {
                font: 12px GothamMedium,sans-serif;
                color: #666;
                margin-bottom: 19px;

            }
            .col-infor {
    
                .col-titulo {
                    font: 12px GothamMedium,sans-serif;
                    color: #666;
                    margin-top: 20px;
                    margin-bottom: 5px;
                }
                .col-text {
                    font: 12px GothamBlack,sans-serif;
                    color: #3f544e;
                }
                @media (max-width: 800px) and (min-width: 600px) {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                }
            }

           

        }


    }
}
.enviar-contabilidade {
    padding: 36px 44px;
    border-radius: 8px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.07);
    border: 1px solid #d5dfdb;
    background-color: #fff;
    margin-bottom: 30px;
    .titulo {
        font: 22px GothamBlack,sans-serif;
        color: #000;
        margin-bottom: 25px;
    }
    .subTitulo {
        font: 12px GothamMedium,sans-serif;
        color:#8c939c;
        margin-bottom: 34px;
    }
    .seleciona-contador {
        grid-area: form;
        display: grid;
        grid-template: "textoUm inputUm "
        "inputDois inputDois"/ 58px 260px;
        grid-row-gap: 29px;
        column-gap: 30px;
        .textCampo {
            font: 12px GothamMedium, sans-serif;
            color: #999999;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            text-align: left;
            height: 38px;
        }


        .textoUm { grid-area: textoUm; }
        .inputUm { grid-area: inputUm; }
        .inputDois { grid-area: inputDois; }
        .cont-select {
            .dropDown {
                width: 260px;
            }
        }

    }

    @media (max-width: 600px) and (min-width: 0px) {
        box-shadow: none;
        border: none;
        padding: 20px 30px;

        .seleciona-contador {
            grid-template: "textoUm textoUm " "inputUm inputUm "
            "inputDois inputDois"/ 1fr;
            .textCampo {
                justify-content: flex-start;
                height: 10px;
            }
        }
    }
    .enviado {
        max-width: 400px;
        font: 12px GothamBlack,sans-serif;
        color: #04b7a7;
        margin-top: 15px;
    }

}

/////////////////////////////////////@at-root
/////////////// ETAPA DOIS ////////////////@at-root
/////////////////////////////////////////@at-root
.desligamento-etapa-dois {
    padding: 36px 44px;
    border-radius: 8px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.07);
    border: 1px solid #d5dfdb;
    background-color: #fff;
    margin-bottom: 25px;
    display: grid;
    grid-template: "titulo titulo"  "subtitulo subtitulo" "listaUm listaDois"/390px 390px;

    .titulo {
        grid-area: titulo;
        font: 22px GothamBlack,sans-serif;
        color: #000;
        margin-bottom: 16px;
    }
    .subtitulo {
        grid-area: subtitulo;
        font: 12px GothamMedium,sans-serif;
        color:#8c939c;
        margin-bottom: 38px;
    }
    .lista-um {
        grid-area: listaUm;
    }
    .lista-dois {
        grid-area: listaDois;
    }

    .chekbox {
        display: flex;
        align-items: center;
        height: 38px;
        .chackboxLabel {
            cursor: pointer;
            width: 18px;
            height: 18px;
            border-radius: 3px;
            border: solid 1px #c1d0ca;
            margin-bottom: 0;
            margin-right: 10px;
            position: absolute;
        }
        .foreignTexto {
            margin-bottom: 0;
            margin-right: 25px;
            cursor: pointer;
            font: 11px GothamBold,sans-serif;
            color: #333333;
            padding-left: 28px;
        }
        input {
            display: none;
        }
        input[type=checkbox]:checked + .chackboxLabel {
            background-image: url("../../../../assets/images/check.svg");
            background-repeat: no-repeat;
            background-position: 4px 5px;
        }
    }
    @media (max-width: 10600px) and (min-width: 1600px) {
        grid-template: "titulo titulo"  "subtitulo subtitulo" "listaUm listaDois"/500px 500px;
    }


    @media (max-width: 1250px) and (min-width: 800px) {
        grid-template: "titulo titulo"  "subtitulo subtitulo" "listaUm listaDois"/1fr 1fr;
    }

    @media (max-width: 800px) and (min-width: 0px) {
        grid-template: "titulo"  "subtitulo" "listaUm" "listaDois"/ 1fr;
        ul {
            margin-bottom: 0;
        }
        .subtitulo {
            margin-bottom: 25px;
        }
    }

    @media (max-width: 600px) and (min-width: 0px) { 
        box-shadow: none;
        border: none;
        padding: 20px 30px;

    }
}

/////////////////////////////////////@at-root
/////////////// ETAPA TREIS ////////////////@at-root
/////////////////////////////////////////@at-root
.desligamento-etapa-treis {
    padding: 36px 44px;
    border-radius: 8px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.07);
    border: 1px solid #d5dfdb;
    background-color: #fff;
    margin-bottom: 25px;

    .formulario-fim {
        max-width: 670px;
        margin-bottom: 32px;
        .titulo {
            font: 22px GothamBlack,sans-serif;
            color: #000;
            margin-bottom: 27px;
        }
        .subTitulo {
            font: 13px GothamBold,sans-serif;
            color: #333;
            margin-bottom: 20px;
        }
        textarea {
            max-width: 100%;
            height: 120px;
            margin-bottom: 30px;
        }

        .chekbox-perguntas {
            display: block;
            ul {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap: 15px;
                margin-bottom: 50px;
                li {
                    display: inline-block;
                    // width: 221px;
                    display: flex;
                    align-items: center;
                }
            }
            .chackboxLabel {
                cursor: pointer;
                width: 18px;
                height: 18px;
                border-radius: 3px;
                border: solid 1px #c1d0ca;
                margin-bottom: 0;
                margin-right: 10px;
                position: absolute;
            }
            .foreignTexto {
                margin-bottom: 0;
                cursor: pointer;
                font: 11px GothamBold,sans-serif;
                color: #3f544e;
                float: left;
                margin-top: 3px;
                padding-left: 28px;
            }
            input {
                display: none;
            }
            input[type=checkbox]:checked + .chackboxLabel {
                    background-image: url("../../../../assets/images/check.svg");
                    background-repeat: no-repeat;
                    background-position: 4px 5px;
            }
        }
        .avalia-ambiente-trabalho {
            display: block;
            padding-top: 15px;
            .clima {
                width: 159px;
                display: inline-block;
                margin-right: 55px;
            }
            .estrutura {
                width: 159px;
                display: inline-block;
            }
        }
        
        .avaliacao-estrelha {
            display: block;
            p {
                display: block;
                margin-bottom: 10px;
                margin-top: -23px;
            }
            ul {
                display: grid;
                grid-template-columns: 20px 20px 20px 20px 20px;
                gap: 15px;
                margin-bottom: 50px;
                li {
                    width: 20px;
                    display: flex;
                    align-items: center;
                }
            }
            .chackboxEstrelha {
                cursor: pointer;
                display: block;
                width: 20px;
                height: 19px;
                float: left;
                background-image: url("../../../../assets/images/star.svg");
                background-repeat: no-repeat;
            }
            input[type=checkbox]:checked + .chackboxEstrelha {
                    background-image: url("../../../../assets/images/star-yellow.svg");
                    background-repeat: no-repeat;
            }

        }
        .ava-star-experi-job {
            ul {
                display: grid;
                grid-template-columns: 20px 20px 20px 20px 20px 20px 20px 20px 20px 20px;
                gap: 15px;
                margin-bottom: 50px;

                    @media (max-width: 410px) and (min-width: 0px) { 
                        grid-template-columns: 20px 20px 20px 20px 20px;
                        
                    }
            }
        }
        .titulo-anex-doc,
        .titulo-obsevacoe-extra {
            font: 22px GothamBlack, sans-serif;
            color: #000;
            margin-bottom: 20px;

        }
        .input-obsevacoe-extra {
            max-width: 397px;
            height: 120px;
        }
        
        .adicionar-doc { 
            grid-area: inputTreis;
            max-width: 340px;
            .dropzone {
                margin-bottom: 10px;
            }
            .inforDocAdd {
                margin-bottom: 5px;
            }
            .imgDocLabel {
                padding-left: 34px;
            }
            .carregando {
                margin-bottom: 10px;
                margin-top: 0;
            }
        }
        .enviar-doc {
            width: 340px;
            margin-top: 10px    ;
        }
    }

    .ArquivosEnviados {
        h5 {
            font: 14px GothamBlack, sans-serif;
            color: #000;
            margin-bottom: 10px;
        }
        .arquivos {
            display: grid;
            grid-template:"nameArquivo dataArquivo btnExclui"
            /262px 254px 100px;
            grid-gap: 20px;
            margin-bottom: 30px;
            .nameArquivo {
                grid-area: nameArquivo;
                justify-content: flex-start;
                align-items: center;
                a {
                    display: block;
                    font: 12px GothamBlack, sans-serif;
                    color: #3f544e;
                    margin-bottom: 3px;
                    word-break: break-word;
                    text-decoration: underline !important;
                }
            }
            .dataArquivo {
                grid-area: dataArquivo;
                font: 12px GothamBold, sans-serif;
                color: #999999;
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }
            .btnExclui {
                grid-area: btnExclui;
                font: 12px GothamBold, sans-serif;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                button {
                    background: none;
                    border: none;
                    color: #e95656;
                }
            }
            @media (max-width: 1500px) and (min-width: 500px) {
                grid-template:"nameArquivo dataArquivo btnExclui"
                /1.3fr 158px 83px;
            }
            @media (max-width: 500px) and (min-width: 0px) {
                grid-template:"nameArquivo nameArquivo"
                "dataArquivo btnExclui"
                / 1fr 83px;
                gap: 10px;
            }

        }
        .nenhum-arq {
            p {
                font: 14px GothamMedium, sans-serif;
                color: #fe8c60;
                margin-bottom: 30px;
            }
        }
    }

    @media (max-width: 600px) and (min-width: 0px) { 
        box-shadow: none;
        border: none;
        padding: 20px 30px;

    }


    @media (max-width: 750px) and (min-width: 480px) { 
        .formulario-fim {
            .chekbox-perguntas {
                > ul {
                    grid-template-columns: 1fr 1fr;
                }
            }
        }
    }

    @media (max-width: 480px) and (min-width: 0px) { 
        .formulario-fim {
            .chekbox-perguntas {
                > ul {
                    grid-template-columns: 1fr;
                }
            }

            .input-obsevacoe-extra {
                max-width: 100%;
            }
        }
    }
}
.desligamento-buttons {
    padding-bottom: 71px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .delete {
        border: none;
        background: none;
        font: 12px GothamBold, sans-serif;
        color: #e95656;
    }
    .saved-back {
        display: flex;
        gap: 20px;
    }
    // .back {
    //     margin-right: 20px;
    // }
    // .saved {
    // }

    @media (max-width: 600px) and (min-width: 0px) {
        padding-left: 30px;
        padding-right: 30px;
    }

    @media (max-width: 470px) and (min-width: 0px) {
        display: grid;
        grid-template: "savedBack" "delete"/1fr;
        gap: 20px;
        text-align: center;

        .delete {
            grid-area: delete;
            
        }

        .saved-back {
            grid-area: savedBack;
            display: grid;
            grid-template: "saved" "back"/1fr;
            .back {
                grid-area: back;
            }
            .saved {
                grid-area: saved;
                width: 100%;
            }
        }

    }
}
