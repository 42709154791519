.page-logs-sistem {
    .filters {
        display: flex;
        gap: 20px;
        align-items: center;
        .titulo {
            font: 16px 'GothamBold',sans-serif;
            letter-spacing: -0.26px;
            color: #000;
        }
        .functionality {
            width: 240px;
        }
        .action,
        .type-user {
            width: 170px;
        }
        .col-busca {
            width: 250px;
        }
        .data {
            width: 200px;
        }
    }
    .selected_contributor {
        display: grid;
        max-width: 600px;
        justify-content: flex-start;
        grid-template-columns: 90px 1fr 100px;
        align-items: center;
        gap: 20px;
        margin-top: 20px;
        > p {
            font: 12px 'GothamBold', sans-serif;
            color: #000;
        }
        .collaborador {
            display: grid;
            grid-template-columns: 37px 1fr;
            gap: 18px;
            align-items: center;
            position: relative;

            .semImgCol {
                height: 37px;
            }
            .foto {
                img {
                    object-fit: cover;
                    width: 100%;
                    height: 37px;
                    border-radius: 60%;
                }
            }
            .infor {
                display: grid;
            }
            strong {
                font: 14px 'GothamMedium',sans-serif;
                letter-spacing: -0.23px;
                color: #000;
                width: 96%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            p {
                position: relative;
                display: inline-block;
                font: 12px 'GothamMedium',sans-serif;
                letter-spacing: -0.2px;
                color: #999999;
                width: 99%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }  
        .remover {
            display: flex;
            gap: 10px;
            border: none;
            background: none;
            padding: 5px;
            font: 11px 'GothamMedium', sans-serif;
            color: #ef4e46;
            &::before {
                content: "";
                display: inline-block;
                background: url("../../assets/images/delete.svg");
                width: 13px;
                height: 13px;
                background-size: 13px 13px;
            }
        }
    }
    .tabela-logs {
        padding-top: 40px;
        display: grid;
        gap: 20px;
        .header-table {
            display: grid;
            grid-template-columns: 70px 200px 200px 200px 1fr 1fr;
            gap: 20px;
            padding: 0 23px;
            height: 38px;
            background: #fff;
            border-radius: 8px;
            border: solid 1px #d5dfdb;
            align-items: center;

            font: 12px "GothamMedium",sans-serif;
            color: #000;
            letter-spacing: -.2px;
        }
        .body-table {
            display: grid;
            gap: 20px;
            .linha {
                display: grid;
                grid-template-columns: 70px 200px 200px 200px 1fr 1fr;
                gap: 20px;
                padding: 0 23px;
                align-items: center;
                min-height: 60px;
                background: #fff;
                border-radius: 8px;
                border: 1px solid #d5dfdb;
                padding-top: 20px;
                padding-bottom: 20px;
                font: 12px "GothamMedium",sans-serif;
                color: #000;
                letter-spacing: -.2px;
                pre {
                    width: 80%;
                    word-break: break-all;
                    display: flex;
                    flex-wrap: wrap;
                    white-space: break-spaces;
                    font: 11px "GothamMedium",sans-serif;
                    color: #000;
                    letter-spacing: -.2px;
                }
            }
        }
    }
}