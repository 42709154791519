.swal-overlay {
    z-index: 999999;
}

.modal-overlay-none.modal-overlay {
    opacity: 0;
    height: 0;
    overflow: hidden;
}

.modal-overlay {
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    padding: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999999;
    opacity: 100;
    overflow: visible;
    overflow-x: scroll;
    transition: all 0.1s linear;

    .container {
        width: 500px;
        min-height: 430px;
        margin: 100px auto;
        margin-bottom: 100px;
        padding: 30px 27px 30px 27px;
        border-radius: 10px;
        background-color: #fff;
        position: relative;

        .botao-close {
            border: none;
            background: none;
            position: absolute;
            right: 26px;
            top: 30px;
        }

        h1 {
            font: 22px 'GothamBlack', sans-serif;
            color: #2bc78b;
            margin-bottom: 25px;
        }
    }

    @media (max-width: 600px) and (min-width: 0px) {
        .container {
            width: 100%;
            min-height: 100%;
            margin: 0 auto;
            border-radius: 0;
        }

    }

    .conteudo-exclui-conta,
    .conteudo-cancelar-assinatura {
        width: 100%;
        text-align: center;

        .ilustration {
            width: 150px;
            height: 150px;
            margin-top: 38px;
            margin-bottom: 29px;

        }

        .titulo {
            font: 22px 'GothamBlack', sans-serif;
            color: #E95656;
            margin-bottom: 23px;
            letter-spacing: -0.36px;
        }

        .infor {
            font: 16px 'GothamMedium', sans-serif;
            color: #8C939C;
            margin-bottom: 78px;
            letter-spacing: -0.26px;

            span {
                color: #3F544E;
            }
        }

        button {
            width: 100%;
        }

    }

    .conteudo-exclui-conta {
        .infor {
            margin-bottom: 29px;

            a {
                color: #3F544E;
            }
        }

    }

    .conteudo-boas-vindas {
        width: 100%;
        text-align: center;

        .logo {
            margin-bottom: 22px;
            margin-left: -20px;
        }

        .titulo {
            font: 22px 'GothamBold', sans-serif;
            color: #04B7A7;
            margin-bottom: 23px;
            letter-spacing: -0.36px;
        }

        .infor {
            font: 22px 'GothamBold', sans-serif;
            color: #3F544E;
            margin-bottom: 43px;
            letter-spacing: -0.29px;
        }

        .ilustracao {
            width: 180px;
            margin-bottom: 22px;
        }

        .fechar {
            background: none;
            border: none;
            font: 12px 'GothamBold', sans-serif;
            color: #3F544E;
            margin-top: 22px;
        }
    }

    .container-sucess {
        text-align: center;

        .img {
            width: 150px;
            height: 150px;
            margin-top: 38px;
            margin-bottom: 25px;
        }

        h1 {
            font: 22px 'GothamBlack', sans-serif;
            color: #2bc78b;
            margin-bottom: 25px;
        }

        p {
            font: 16px 'GothamMedium', sans-serif !important;
            color: #8c939c !important;
            margin-bottom: 35px;
        }

        .btn-ok {
            width: 225px;
            height: 42px;
            background-color: #e6ecec;
            font: 12px 'GothamBold', sans-serif !important;
            color: #3f544e;
            transition: 0.4s;
            border-radius: 8px;
            border: none;

            &:hover {
                background-color: #dadede;
            }
        }
    }

    .modal-form {
        overflow: scroll;

        .titulo {
            margin-bottom: 20px;

            h4 {
                font: 25px GothamBlack, sans-serif;
                letter-spacing: -0.41px;
                color: #000;
                margin-bottom: 15px;
            }
        }

        .ul-form {
            display: grid;
            grid-gap: 19px;
            gap: 19px;
            margin-bottom: 20px;

            >li {
                display: flex;
                align-items: center;
                width: 100%;
                grid-gap: 30px;
                gap: 30px;

                .intemA {
                    width: 30%;
                    text-align: right;

                    p {
                        font: 12px 'GothamMedium', sans-serif;
                        color: #999999;
                    }
                }

                .textarea-text {
                    p {
                        margin-top: -44px;
                    }
                }

                .intemB {
                    width: 70%;
                    text-align: left;
                    font: 12px 'GothamBold', sans-serif;
                    color: #3f544e;
                    word-break: break-word;
                    position: relative;

                    .cont-select {
                        position: relative;
                        width: 100%;

                        .dropDown,
                        .select- {
                            width: 100% !important;
                        }
                    }

                    textarea,
                    select,
                    input {
                        width: 100%;
                    }

                    textarea {
                        height: 110px;
                    }

                    .textError {
                        margin-bottom: -14px;
                        margin-top: 4px;
                        position: absolute;
                    }

                }

            }


            @media (max-width: 390px) and (min-width: 0px) {
                li {
                    display: grid;
                    gap: 10px;

                    .intemA {
                        width: 100%;
                        text-align: left;
                    }

                    .intemB {
                        width: 100%;

                        .cont-select {
                            width: 100%;
                        }
                    }

                }
            }

        }

        .btn-salva {
            width: 100%;

            button {
                width: 100%;
            }

            .cancelar {
                border: none;
                background: none;
                width: auto;
                display: block;
                margin: 0 auto;
                margin-top: 28px;
                font: 12px 'GothamBold', sans-serif;
                color: #3F544E;
                letter-spacing: -0.27px;
            }
        }

        .infor-cal {
            display: grid;
            grid-template-columns: 35px 1fr 109px;
            gap: 19px;
            margin-bottom: 18px;
            border-bottom: 1px solid #E6ECEC;
            padding-bottom: 18px;
            margin-bottom: 25px;

            .img-col {
                width: 35px;
                height: 35px;

                img {
                    width: 35px;
                    height: 35px;
                    border-radius: 60%;
                    object-fit: cover;
                }
            }

            .semImgCol {
                width: 35px;
                height: 35px;
            }

            .nome-setor {
                padding-top: 1px;

                .nome {
                    font: 14px GothamMedium, sans-serif;
                    letter-spacing: -0.23px;
                    color: #000;
                    display: grid;
                    width: 100%;

                    span {
                        word-break: normal;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        width: 100%;

                    }
                }

                .setor {
                    font: 12px GothamMedium, sans-serif;
                    letter-spacing: -0.2px;
                    margin-top: 3px;
                    color: #999999;
                    display: grid;
                    width: 100%;

                    p {
                        word-break: normal;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        width: 100%;

                    }
                }
            }

            .data-admissao {
                font: 12px Gotham, sans-serif;
                letter-spacing: -0.2px;
                margin-top: 3px;
                color: #999999;
                text-align: right;

                span {
                    display: block;
                    color: #3F544E;
                    margin-top: 3px;
                }
            }


            @media (max-width: 500px) and (min-width: 0px) {
                grid-template: "ImgCol nomeSetor"
                    "dataAdmissao dataAdmissao"/ 35px 1fr;

                .infor-cal {
                    grid-area: ImgCol;
                }

                .nome-setor {
                    grid-area: nomeSetor;
                }

                .data-admissao {
                    grid-area: dataAdmissao;
                    display: flex;
                    align-items: flex-end;

                    span {
                        margin-left: 20px;
                    }
                }

            }
        }

        .infor-detalhes {
            display: grid !important;
            gap: 35px !important;
            border-bottom: 1px solid #E6ECEC;
            padding-bottom: 22px;
            margin-bottom: 22px;

            li {
                display: grid !important;
                grid-template-columns: 135px 135px;
                gap: 10px;
                align-items: center;

                .nome {
                    font: 12px GothamMedium, sans-serif;
                    letter-spacing: -0.2px;
                    color: #000000;
                }

                .infor {
                    font: 11px GothamMedium, sans-serif;
                    letter-spacing: -0.2px;
                    color: #3F544E;
                    text-align: center;
                }

                @media (max-width: 400px) and (min-width: 0px) {
                    grid-template-columns: 1fr;
                    gap: 10px !important;

                    .infor {
                        text-align: left;
                    }
                }
            }
        }

        .historico-ferias {
            padding: 0 0 15px 0;

            .titulo-box {
                font: 22px GothamBlack, sans-serif;
                letter-spacing: -0.36px;
                color: #000;
                margin-bottom: -20px;
                margin-right: 32px;
                margin-top: 3px;
                float: left;

                @media (max-width: 500px) and (min-width: 0px) {
                    float: none;
                    display: block;
                    margin-bottom: 10px;
                }
            }

            .nav-tabs {
                width: 300px;
                height: 29px;
                display: inline-block;
                background: #EEF4F4;
                border-radius: 8px;
                border-bottom: none;
                margin-top: 2px;

                a:nth-child(3),
                a:nth-child(2) {
                    margin-left: 10px !important;
                }


                .nav-link {
                    height: 29px;
                    min-width: 91px;
                    display: inline-block;
                    font: 11px GothamBold, sans-serif;
                    letter-spacing: 0.2px;
                    color: #000;
                    border-radius: 8px;
                    padding-left: 13px;
                    padding-right: 13px;
                    padding-top: 7px;
                    padding-bottom: 0;
                    transition: 0.2s;

                    &:hover {
                        background: #D5E4E1;
                    }
                }

                .nav-link.active {
                    background: #D5E4E1;
                }
            }

            .lista-vazia {
                text-align: center;

                p {
                    font: 10px GothamMedium, sans-serif;
                    letter-spacing: -0.32px;
                    color: #3F544E;
                    margin-bottom: 10px;
                }

                img {
                    max-width: 150px;
                }
            }

            .lista-historico {
                margin-top: 9px;

                .lista {
                    display: grid;
                    gap: 24px;
                    min-height: 78px;
                    max-height: 175px;
                    overflow-y: auto;
                    padding-right: 10px;

                    li:last-child {
                        border-bottom: none;
                    }

                    li {
                        border-bottom: 1px solid #e6ecec;

                        // height: 49px;
                        .info-historico {
                            padding-bottom: 10px;
                            flex-wrap: wrap;

                            p {
                                display: inline-block;
                                font: 11px GothamMedium, sans-serif;
                                letter-spacing: -0.18px;
                                color: #999999;
                                width: 105px;

                                span {
                                    color: #3f544e;
                                }

                                margin-bottom: 7px;
                            }
                        }

                        .box-ferias-coletiva {
                            padding-bottom: 10px;

                            // border-bottom: 1px solid #D8D8D8;
                            .titulo-box {
                                font: 14px GothamBlack, sans-serif;
                                color: #000;
                                letter-spacing: -0.41px;
                                margin-bottom: 7px;
                                display: block;
                            }

                            .infor {
                                display: flex;
                                justify-content: space-between;
                                width: 100%;
                                gap: 10px;

                                @media (max-width: 400px) and (min-width: 0px) {
                                    display: grid;
                                }

                                .grupo,
                                .data {
                                    font: 10px GothamMedium, sans-serif;
                                    color: #3F544E;
                                }
                            }
                        }
                    }
                }
            }
        }

    }

    .modal-form.verDetalhesCol {
        overflow: auto;

        .ano-referente {
            font: 22px GothamBold, sans-serif;
            color: #3F544E;
        }

        .infor-detalhes {
            display: grid !important;
            gap: 11px !important;
            border-bottom: 1px solid #E6ECEC;
            margin-bottom: 22px;
            border: 1px dashed #ccc;
            padding: 15px 20px;
            cursor: pointer;
        }
    }
}

.modal-ponto-editarHorario {
    .header {
        border-bottom: 1px solid #D5DFDB;

        h2 {
            font: 25px 'GothamBlack', sans-serif;
            letter-spacing: -0.41px;
            color: #000;
            margin-bottom: 20px;
        }

        p {
            font: 16px 'GothamBold', sans-serif;
            letter-spacing: -0.26px;
            color: #3F544E;
            margin-bottom: 27px;
        }
    }

    .horario-original {
        padding-top: 30px;
        display: grid;
        grid-template-columns: 150px 1fr;
        padding-bottom: 36px;
        margin-bottom: 15px;
        border-bottom: 3px solid #D5DFDB;

        .titulo {
            h3 {
                font: 12px 'GothamBlack', sans-serif;
                letter-spacing: -0.2px;
                color: #4A535E;
            }
        }

        .infor {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 10px;

            p {
                font: 12px 'GothamMedium', sans-serif;
                color: #4A535E;
                text-align: left;
            }

            span {
                font: 18px 'GothamBold', sans-serif;
                color: #000;
                text-align: left;
                width: 33px;
            }
        }
    }

    .infor-form {
        background: #EEF4F4;
        border-radius: 6px;
        height: 57px;
        padding: 13px 13px;

        p {
            font: 11px 'GothamMedium', sans-serif;
            letter-spacing: -0.18px;
            color: #3F544E;
        }
    }

    .form {
        display: grid;
        grid-template-columns: 1fr 372px;
        padding-top: 17px;
        padding-bottom: 9px;
        margin-bottom: 24px;
        border-bottom: 1px solid #D5DFDB;

        .titulo {
            h3 {
                font: 12px 'GothamBlack', sans-serif;
                letter-spacing: -0.2px;
                color: #4A535E;
            }
        }

        .bloco-form {
            display: grid;
            grid-template-columns: 1fr 1fr;

            .input-text {
                display: grid;
                grid-template-columns: 1fr 81px;
                text-align: right;
                gap: 21px;
                align-items: center;
                margin-bottom: 25px;

                input {
                    width: 81px;
                }

                .textError {
                    margin-top: 1px;
                    margin-bottom: -22px;
                }
            }
        }
    }

    .btn-salva {
        width: 100%;

        button {
            width: 100%;
        }

        .cancelar {
            border: none;
            background: none;
            width: auto;
            display: block;
            margin: 0 auto;
            margin-top: 28px;
            font: 12px 'GothamBold', sans-serif;
            color: #3F544E;
            letter-spacing: -0.27px;
        }
    }
}

.modal-ponto-justicar {
    .header {
        border-bottom: none;
        padding-bottom: 0;

        .editar-horario {
            h3 {
                font: 18px 'GothamBlack', sans-serif;
                letter-spacing: -0.29px;
                color: #000;
                margin-top: 30px;
                padding-bottom: 0;
            }

            .form-edit-hr-justificativa {
                display: grid;
                grid-template-columns: 1fr 192px;

                p {
                    margin-bottom: 0;
                    font: 12px 'GothamMedium', sans-serif;
                    letter-spacing: -0.2px;
                    color: #8C939C;
                    padding-top: 3px;
                }

                .radios {
                    display: grid;
                    grid-template-columns: 1fr 1.5fr;

                    .radioNovo {
                        width: 100%;

                        .foreignTexto {
                            font: 11px 'GothamBold', sans-serif;
                            letter-spacing: -0.18px;
                            color: #3F544E;

                        }
                    }
                }
            }

        }
    }

    .justificativa-form {
        border-bottom: 1px solid #D5DFDB;

        .titulo {
            font: 18px 'GothamBlack', sans-serif;
            letter-spacing: -0.29px;
            color: #000;
        }

        textarea {
            width: 100%;
            height: 110px;
            margin-bottom: 10px;
        }

        .anexar-arquivos {
            display: grid;
            grid-template-columns: 1fr 140px;
            gap: 20px;
            padding-bottom: 20px;

            button {
                height: 38px;
            }

            .dropzone {
                .imgDocLabel {
                    padding-left: 20px;
                }
            }
        }

        .list-arquivos-anexado {
            padding-bottom: 20px;
            display: grid;
            gap: 20px;

            // border-bottom: 1px solid #D5DFDB;
            .arquivos-anexado {
                display: grid;
                grid-template-columns: 1fr 88px;
                align-items: center;

                .exclui {
                    height: 10px;
                    border: none;
                    background: none;
                    padding: 0;
                    font: 11px 'GothamMedium', sans-serif;
                    color: #E95656;
                }

                .nome {
                    font: 14px "GothamMedium", sans-serif;
                    color: #3F544E;
                    word-break: normal;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    max-width: 99%;
                    display: block;
                    margin-bottom: 8px;
                }

                .data {
                    font: 10px "GothamMedium", sans-serif;
                    color: #999999;
                    word-break: normal;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    max-width: 99%;
                    display: block;
                }
            }
        }
    }

    .justificativa-enviada {
        padding-top: 25px;
        padding-bottom: 30px;

        .titulo {
            font: 18px "GothamBlack", sans-serif;
            letter-spacing: -0.29px;
            color: #000;
            margin-bottom: 0;
        }

        .justificativa {
            padding-top: 20px;
            display: grid;
            grid-template-columns: 1fr 30px;
            padding-bottom: 30px;
            border-bottom: 1px solid #D5DFDB;

            .nome {
                font: 12px "GothamMedium", sans-serif;
                color: #3F544E;
                margin-bottom: 26px;
            }

            .aquivo {
                font: 12px "GothamMedium", sans-serif;
                color: #3F544E;
                margin-bottom: 10px;
            }

            .data {
                font: 10px "GothamMedium", sans-serif;
                color: #999999;
            }

            .dropdown-menu {
                width: 143px;
                transform: translate(-134px, 32px) !important;
            }
        }

    }
}

.modal-ponto-jornada {
    .titulo {
        font: 25px 'GothamBlack', sans-serif;
        letter-spacing: -0.41px;
        color: #000;
        margin-bottom: 20px;
    }

    .name-jornada {
        display: grid;
        grid-template-columns: 1fr 314px;
        align-items: center;

        P {
            font: 12px 'GothamMedium', sans-serif;
            letter-spacing: -0.2px;
            color: #8C939C;
        }

        input {
            width: 100%;
        }

        .textError {
            margin-top: 2px;
            margin-bottom: -16px;
        }
    }

    .horarios {
        .titulo {
            font: 22px 'GothamBlack', sans-serif;
            letter-spacing: -0.36px;
            color: #000;
            margin-bottom: 20px;
            margin-top: 23px;
        }

        .form-mesmo-horario {
            display: grid;
            grid-template-columns: 150px 1fr;
            gap: 10px;
            align-items: center;
            margin-bottom: 20px;

            P {
                font: 12px 'GothamMedium', sans-serif;
                letter-spacing: -0.2px;
                color: #8C939C;
                padding-top: 3px;
            }
        }

        .form-dia {
            .bloco-form {
                display: grid;
                grid-template-columns: 1fr 139px 139px;
                align-items: center;
                gap: 10px;
                margin-bottom: 15px;
                padding-bottom: 35px;
                border-bottom: 3px solid #D5DFDB;

                .nome {
                    font: 12px 'GothamBlack', sans-serif;
                    letter-spacing: -0.2px;
                    color: #4A535E;
                    padding-top: 3px;
                }

                .input-text {
                    display: grid;
                    grid-template-columns: 1fr 81px;
                    text-align: right;
                    gap: 15px;
                    align-items: center;

                    .start-end-text {
                        font: 12px 'GothamMedium', sans-serif;
                        color: #4A535E;
                    }

                    input {
                        width: 81px;
                    }

                    .textError {
                        margin: 1px 4px -13px 0;
                    }
                }
            }
        }

        .form-finalSemana,
        .bloco-form-dias {
            display: grid;
            gap: 20px;
            padding-bottom: 35px;
            margin-bottom: 15px;
            border-bottom: 3px solid #D5DFDB;

            li {
                display: grid;
                grid-template-columns: 1fr 139px 139px;
                gap: 10px;
                align-items: center;

                .dia {
                    p {
                        font: 12px 'GothamBlack', sans-serif;
                        letter-spacing: -0.2px;
                        color: #4A535E;
                        padding-top: 3px;
                    }
                }

                .input-text {
                    display: grid;
                    grid-template-columns: 1fr 81px;
                    align-items: center;
                    text-align: right;
                    gap: 15px;

                    .text {
                        font: 12px 'GothamMedium', sans-serif;
                        color: #4A535E;
                    }

                    input {
                        width: 100%;
                    }

                    .textError {
                        margin: 1px 4px -13px 0;
                    }

                }
            }
        }

        .bloco-interval,
        .bloco-finalSemana {
            // padding-bottom: 35px;
            margin-bottom: 15px;
            border-bottom: 3px solid #D5DFDB;

            .form-finalSemana {
                border: none;
                padding-bottom: 20px;
            }

        }
    }

    .total-hora {
        margin-top: 23px;
        margin-bottom: 27px;

        p {
            font: 18px 'GothamBlack', sans-serif;
            letter-spacing: -0.29px;
            color: #000000;

        }
    }






    .btn-salva {
        width: 100%;

        button {
            width: 100%;
            color: #fff;
        }

        .cancelar {
            border: none;
            background: none;
            width: auto;
            display: block;
            margin: 0 auto;
            margin-top: 28px;
            font: 12px 'GothamBold', sans-serif;
            color: #3F544E;
            letter-spacing: -0.27px;
        }
    }
}

.modal-ponto-resumo {
    .titulo {
        font: 25px 'GothamBlack', sans-serif;
        letter-spacing: -0.41px;
        color: #000;
        margin-bottom: 28px;
    }

    .infor-col {
        display: grid;
        grid-template-columns: 60px 1fr;
        gap: 20px;
        align-items: center;
        padding-bottom: 20px;
        border-bottom: 1px solid #D5DFDB;
        margin-bottom: 14px;

        .foto-colaborador {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            object-fit: cover;
        }

        .semImgCol {
            width: 60px;
            height: 60px;
        }

        .nome-cargo {
            word-break: break-all;
            display: grid;
            gap: 5px;
            align-items: center;

            .nome {
                font: 22px "GothamBlack", sans-serif;
                letter-spacing: -0.36px;
                color: #000000;
                word-break: normal;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                max-width: 99%;
                display: inline-block;
            }

            .cargo {
                font: 12px "GothamMedium", sans-serif;
                letter-spacing: -0.2px;
                color: #999999;
                word-break: normal;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                max-width: 99%;
                display: inline-block;
            }
        }

    }

    .infor-jornada {
        margin-bottom: 30px;

        h6 {
            font: 12px "GothamMedium", sans-serif;
            color: #666666;
            margin-bottom: 10px;
        }

        .name-journey {
            font: 12px "GothamBold", sans-serif;
            color: #3F544E;
            margin-bottom: 10px;
        }

        .list-periods {
            display: grid;
            gap: 10px;
            padding-left: 20px;
            margin-top: 15px;

            p {
                font: 11px "GothamBold", sans-serif;
                display: grid;
                grid-template-columns: 80px 1fr;
                gap: 20px;
            }
        }
    }

    .infor-horas {
        padding-bottom: 20px;
        border-bottom: 1px solid #D5DFDB;
        margin-bottom: 20px;
        margin-top: 20px;

        h6 {
            font: 12px "GothamMedium", sans-serif;
            color: #666666;
            margin-bottom: 10px;
        }

        .total {
            font: 16px "GothamBlack", sans-serif;
            color: #000000;
        }

        .hr-minuto {
            display: flex;
            align-items: center;
            text-align: center;
            gap: 10px;
            font: 12px "GothamBold", sans-serif;
            color: #3F544E;
        }

        p {
            font: 11px "GothamMedium", sans-serif;
            color: #666666;
        }
    }

    .btn-fechar {
        display: flex;
        justify-content: center;
        padding-top: 15px;
        padding-bottom: 20px;

        button {
            border: none;
            background: none;
            font: 12px "GothamBold", sans-serif;
            letter-spacing: -0.27px;
            color: #3F544E;
        }
    }

}

.modal-post-mural-adm {
    padding-left: 0 !important;
    padding-right: 0 !important;

    // display: grid;
    .titulo {
        font: 22px 'GothamBlack', sans-serif;
        letter-spacing: -0.36px;
        color: #000;
        margin-bottom: 28px;
        padding: 0 30px;
        height: 20%;
        display: block;
    }

    .form-anuncio {
        height: 80vh;
        display: block;

        .form {
            height: 60vh;
            border: 1px solid #D5DFDB;
            margin-bottom: 30px;

            input {
                height: 100%;
                width: 100%;
                border: none;
            }

            textarea {
                width: 100%;
                height: 50vh;
                border-radius: 0;
                border: none;
                border-top: 1px solid #D5DFDB;
                box-shadow: none;
            }
        }

        .botoes {
            height: 20%;
            text-align: center;

            .enviar {
                margin: 0 auto;
                width: 315px;
                margin-bottom: 10px;

                @media (max-width: 320px) and (min-width: 0px) {
                    width: 90%;
                }
            }

            .excluir {
                border: none;
                background: none;
                color: #e95656;
                font: 12px GothamBold, sans-serif;
                margin-top: 15px;
            }

            .cancelar {
                border: none;
                background: none;
                padding: 0;
                margin-top: 15px;
                color: #e95656;
                font: 12px GothamBold, sans-serif;
            }

            .btn-2 {
                display: flex;
                gap: 30px;
                align-items: center;
                justify-content: center;
            }
        }
    }

}

.modal-accountant {
    .infor-cal {
        display: grid;
        grid-template-columns: 35px 1fr;
        gap: 18px;
        margin-bottom: 18px;
        padding-bottom: 15px;
        border-bottom: 1px solid #D8D8D8;

        .img-load {
            width: 35px;
            height: 35px;
        }

        .img-col {
            width: 35px;
            height: 35px;

            img {
                width: 35px;
                height: 35px;
                border-radius: 60%;
                object-fit: cover;
            }
        }

        .semImgCol {
            width: 35px;
            height: 35px;

        }

        .nome-setor {
            padding-top: 1px;

            .nome {
                font: 14px GothamMedium, sans-serif;
                letter-spacing: -0.23px;
                color: #000;
                display: grid;
                width: 100%;
                margin-bottom: 0;

                span {
                    word-break: normal;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    width: 100%;

                }
            }

            .setor {
                font: 12px GothamMedium, sans-serif;
                letter-spacing: -0.2px;
                margin-top: 3px;
                color: #999999;
            }
        }
    }


    .informacoes {
        max-width: 500px;

        // margin-top: 20px;
        &>.infor {
            p {
                font: 15px GothamMedium, sans-serif;
                letter-spacing: -0.2px;
                line-height: 17px;
                color: #8C939C;
                margin-bottom: 23px;

                a {
                    color: #000;
                    text-decoration: underline !important;
                }
            }
        }

        .formulario {
            width: 100%;
            margin-bottom: 18px;

            textarea {
                width: 100% !important;
                height: 95px;
            }

            select {
                width: 100%;
            }

            .display-grid {
                display: grid;
                grid-template-columns: 50px 1fr;
                gap: 20px;
                align-items: center;
                margin-bottom: 20px;

                p {
                    font: 12px "GothamMedium", sans-serif;
                    color: #999;
                }


                @media (max-width: 400px) and (min-width: 0px) {
                    grid-template-columns: 1fr;
                    gap: 10px;
                }
            }
        }

        .btns {
            .enviar {
                width: 100%;
            }
        }

    }
}

.popup-vacation-add {
    .infor-cal {
        display: grid;
        grid-template-columns: 35px 1fr 109px;
        gap: 19px;
        margin-bottom: 18px;
        border-bottom: 1px solid #E6ECEC;
        padding-bottom: 18px;
        margin-bottom: 25px;

        .img-load {
            width: 35px;
            height: 35px;
        }

        .img-col {
            width: 35px;
            height: 35px;

            img {
                width: 35px;
                height: 35px;
                border-radius: 60%;
                object-fit: cover;
            }
        }

        .semImgCol {
            width: 35px;
            height: 35px;
        }

        .nome-setor {
            padding-top: 1px;

            .nome {
                font: 14px GothamMedium, sans-serif;
                letter-spacing: -0.23px;
                color: #000;
                display: grid;
                width: 100%;

                span {
                    word-break: normal;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    width: 100%;

                }
            }

            .setor {
                font: 12px GothamMedium, sans-serif;
                letter-spacing: -0.2px;
                margin-top: 3px;
                color: #999999;
                display: grid;
                width: 100%;

                p {
                    word-break: normal;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    width: 100%;

                }
            }
        }

        .data-admissao {
            font: 12px "GothamMedium", sans-serif;
            letter-spacing: -0.2px;
            margin-top: 3px;
            color: #999999;
            text-align: right;

            span {
                display: block;
                color: #3F544E;
                margin-top: 3px;
            }

            .react-loading-skeleton {
                margin-left: auto;
                margin-right: 0;
            }
        }


        @media (max-width: 500px) and (min-width: 0px) {
            grid-template: "ImgCol nomeSetor"
                "dataAdmissao dataAdmissao"/ 35px 1fr;

            .infor-cal {
                grid-area: ImgCol;
            }

            .nome-setor {
                grid-area: nomeSetor;
            }

            .data-admissao {
                grid-area: dataAdmissao;
                display: flex;
                align-items: flex-end;

                span {
                    margin-left: 20px;
                }
            }

        }
    }

    .lista-anos {
        .box-details {
            margin-bottom: 23px;
            padding-bottom: 30px;
            border-bottom: 1px solid #E6ECEC;

            .titulo {
                font: 18px "GothamBlack", sans-serif;
                color: #000;
                margin-bottom: 25px !important;
                display: flex;
                justify-content: space-between;

                button {
                    background: none;
                    border: none;
                    font: 12px "GothamBold", sans-serif;
                    letter-spacing: -0.18px;
                    color: #3F544E;
                }
            }

            .infor-geral {
                gap: 0 !important;
                margin-bottom: 0;

                li {
                    margin-bottom: 0;

                    .nome {
                        font: 12px "GothamMedium", sans-serif;
                        letter-spacing: -0.2px;
                        color: #000000;
                        width: 148px;
                        border-right: 1px solid #E6ECEC;
                        margin-bottom: 0;
                        padding-top: 12px;
                        padding-bottom: 12px;
                    }

                    .infor {
                        font: 11px "GothamMedium", sans-serif;
                        letter-spacing: -0.2px;
                        color: #3F544E;
                        text-align: center;
                        margin-bottom: 0;
                    }
                }
            }

            .list-requisicoes-ferias {
                padding-top: 10px;

                .sub-titulo {
                    font: 22px "GothamBlack", sans-serif;
                    letter-spacing: -0.18px;
                    color: #000;
                    margin-bottom: 26px;
                }

                .infor-requisicoes {
                    display: grid;
                    gap: 17px;
                    padding-bottom: 23px;
                    border-bottom: 1px solid #E6ECEC;

                    .status,
                    .dias-abono-total,
                    .inicio-fim {
                        display: flex;
                        gap: 20px;

                        p {
                            font: 11px "GothamMedium", sans-serif;
                            letter-spacing: -0.18px;
                            color: #999999;

                            span {
                                color: #3F544E;
                                margin-left: 10px;
                                text-transform: capitalize;
                            }

                        }
                    }

                    .inicio-fim {
                        gap: 40px;
                    }
                }

                .infor-requisicoes-vazia {
                    font: 14px GothamBold, sans-serif;
                    color: #3F544E;
                    letter-spacing: -0.32px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                }

                .infor-requisicoes:last-child {
                    border-bottom: none;
                    padding-bottom: 0;
                }

            }

            .list-requisicoes-ferias.requisicoes {
                margin-top: 30px;
                padding-bottom: 20px;
            }

            .btn-Requisitar {
                margin-top: 44px;
                width: 100%;
            }
        }

        .box-details:last-child {
            border-bottom: none;
            padding-bottom: 0;
        }

    }

    .radio-verificacao-ano {
        .infor {
            font: 14px "GothamMedium", sans-serif;
            color: #000000;
            margin-bottom: 28px;
        }

        .list-ferias-naoAtualizada {
            .item {
                display: grid;
                grid-template-columns: 83px 196px 106px 63px;

                .ultilizado,
                .periodo,
                .referencia {
                    border-right: 1px solid #E6ECEC;

                }

                .saldo,
                .ultilizado,
                .periodo {
                    padding-left: 19px;
                    padding-right: 19px;
                }

                @media (max-width: 500px) and (min-width: 0px) {
                    grid-template-columns: 74px 2fr 75px 56px;

                    .saldo,
                    .ultilizado,
                    .periodo {
                        padding-left: 10px;
                        padding-right: 10px;

                    }
                }
            }

            .item.head {
                font: 12px "GothamMedium", sans-serif;
                color: #3F544E;

                p {
                    padding-top: 5px;
                    padding-bottom: 13px;
                }
            }

            .item.body {
                font: 11px "GothamBold", sans-serif;
                letter-spacing: -0.18px;
                color: #999999;

                p {
                    padding-top: 10px;
                    padding-bottom: 10px;
                }
            }

        }

        .btn-continua {
            width: 100%;
            margin-top: 36px;
        }
    }

    .ul-form {
        margin-bottom: 0 !important;
        margin-top: 20px;

        .intemB {
            textarea {
                height: 110px;
            }

            textarea,
            input {
                width: 100% !important;
            }

            .ano-referente {
                font: 22px GothamBold, sans-serif;
                color: #3F544E;
            }
        }

        @media (max-width: 500px) and (min-width: 0px) {
            li {
                display: grid !important;
                gap: 10px !important;

                .intemB,
                .intemA {
                    width: 100% !important;
                    text-align: left !important;
                }

            }
        }

        .btn-iniciar-ferias {
            display: grid;
            grid-template-columns: 121px 300px;
            gap: 29px;

            @media (max-width: 600px) and (min-width: 400px) {
                width: 100%;
                grid-template-columns: 134px 1fr;

            }

            @media (max-width: 400px) and (min-width: 0px) {
                grid-template-columns: 1fr;
                gap: 19px;

            }
        }
    }

    .btn-salva {
        margin-top: 20px;

        .save {
            width: 100%;
        }

        .cancelar {
            border: none;
            background: none;
            width: auto;
            display: block;
            margin: 28px auto 0;
            font: 12px "GothamBold", sans-serif;
            color: #3f544e;
            letter-spacing: -.27px;
        }
    }
}